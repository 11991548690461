import { getAxiosHeaders, requestMaker } from "../../../shared/providers/requestMaker";
import { apiPath } from "../../../shared/utils/apiConfig";
import { serverTrackingURL } from "../../../shared/utils/Config";
import { REQUEST_TYPES } from "../../../shared/utils/Constants";

export const getPreferences = async (userId: string) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.GET,
    url: `${serverTrackingURL}${apiPath?.GET_PREFERENCES}${userId}`,
    headers: getAxiosHeaders(false, true, false),
  });
  return response;
};

export const setPreferences = async (data, loggedInUserMobile) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.PUT,
    url: `${serverTrackingURL}${apiPath?.SET_PREFERENCES}`,
    headers: getAxiosHeaders(false, true, false),
    data: {
      mobileNumber: loggedInUserMobile,
      configData: data
    }
  });
  return response;
};
