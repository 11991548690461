import React from "react";
import { useTranslation } from "react-i18next";
import { colorCodes, fontFamily } from "../styles/styles.config";

function Footer() {
  const { t } = useTranslation();
    
  const FooterStyles = {
    position:"fixed",
    display: "flex",
    justifyContent: "center",    
    width: "100%",
    height: "26px",
    textAlign: "center",
    color: colorCodes.dustyGray,
    backgroundColor: colorCodes.white,
    paddingBottom: "0.5rem",
    fontFamily: fontFamily.Roboto,
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "26px",
    letterSpacing: "0.2px",
    bottom:"0"
  } as React.CSSProperties;
  return <div style={FooterStyles}>{t("footer_text")}</div>;
}

export default Footer;
