import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { colorCodes } from "../styles/styles.config";
import { getStorageValue } from "../utils/common";
import { localStorageNames } from "../utils/Constants";

const StyledBox = styled(Box)` 
    display: block;

    @media (min-width: 900px) {
        display: none;        
    }
`;

const StyledList = styled(List)` 
    width: 280px;
`;

export default function MobileMenu({ isOpen, handleViewProfile, handlePreferences,handleContacts,handleSignOut }) {
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = React.useState(true);  

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
    isOpen(open);
  };

  const list = () => (
    <StyledBox    
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}      
    >
      <StyledList>
        <ListItem key="" disablePadding>
          <ListItemButton> 
            <ChevronLeftIcon />
          </ListItemButton>
        </ListItem>
        <Divider/>
        <ListItem 
          key={t("profile_text")}
          disablePadding
          sx={{                            
            backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("profile_text")) ? colorCodes?.tango : "transparent",
            color: (getStorageValue(localStorageNames?.currentPage) === t("profile_text")) ? colorCodes?.white : colorCodes?.black,
            "&:hover": {
              backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("profile_text")) ? colorCodes?.tango : "transparent",
            }
          }}
        >
          <ListItemButton onClick={handleViewProfile}>              
            <ListItemText primary={t("profile_text")} />
          </ListItemButton>
        </ListItem>
        <ListItem 
          key={t("menu_item_preferences")}
          disablePadding
          sx={{                            
            backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_preferences")) ? colorCodes?.tango : "transparent",
            color: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_preferences")) ? colorCodes?.white : colorCodes?.black,
            "&:hover": {
              backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_preferences")) ? colorCodes?.tango : "transparent",
            }
          }}
        >
          <ListItemButton onClick={handlePreferences}>              
            <ListItemText primary={t("menu_item_preferences")} />
          </ListItemButton>
        </ListItem>
        <ListItem 
          key={t("menu_item_contacts")}
          disablePadding
          sx={{                            
            backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_contacts")) ? colorCodes?.tango : "transparent",
            color: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_contacts")) ? colorCodes?.white : colorCodes?.black,
            "&:hover": {
              backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_contacts")) ? colorCodes?.tango : "transparent",
            }
          }}
        >
          <ListItemButton onClick={handleContacts}>              
            <ListItemText primary={t("menu_item_contacts")} />
          </ListItemButton>
        </ListItem>          
        <ListItem 
          key={t("menu_item_signout")}
          disablePadding
          sx={{                            
            backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_signout")) ? colorCodes?.tango : "transparent",
            color: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_signout")) ? colorCodes?.white : colorCodes?.black,
            "&:hover": {
              backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_signout")) ? colorCodes?.tango : "transparent",
            }
          }} 
        >
          <ListItemButton onClick={handleSignOut}>              
            <ListItemText primary={t("menu_item_signout")} />
          </ListItemButton>
        </ListItem>        
      </StyledList>      
    </StyledBox>
  );

  return (
    <div>      
      <React.Fragment key={"right"}>          
        <SwipeableDrawer
          anchor={"right"}
          open={openDrawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableBackdropTransition={true}                     
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>      
    </div>
  );
}