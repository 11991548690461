import { getAxiosHeaders, requestMaker } from "../../../shared/providers/requestMaker";
import { apiPath } from "../../../shared/utils/apiConfig";
import { getStorageValue } from "../../../shared/utils/common";
import { authBaseURL, authURl } from "../../../shared/utils/Config";
import { app_type, deviceType, localStorageNames, REQUEST_TYPES } from "../../../shared/utils/Constants"

export const userSignIn = async (data) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.POST,
    url: `${authURl}${apiPath?.USER_SIGN_IN}`,
    headers: getAxiosHeaders(true, false, false),
    data: data,
  });
  return response;
};

// export const userSignOut = async () => {
//   const response = await requestMaker({
//     method: REQUEST_TYPES?.POST,
//     url: `${authURl}${apiPath?.USER_SIGN_OUT}`,
//     headers: getAxiosHeaders(true, false, true),
//   });
//   return response;
// };

import axios from "axios";
export const getLoggedInUserInfo = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${authBaseURL}${apiPath.GET_LOGGED_IN_USER_INFO}`,
      withCredentials: true, // Include cookies in the request
    });
    return response;
  } catch (error) {
    return {
      status: false,
      result: error
    }
  }
};

export const userSignOut = async () => {
  try {
    const response = await axios({
      method: REQUEST_TYPES.POST,
      url: `${authURl}${apiPath?.USER_SIGN_OUT}`,
      withCredentials: true,
      headers: {
        "Authorization": `Bearer ${JSON.parse(getStorageValue(localStorageNames?.loginDetails))?.access_token}`
      }
    });
    return response;
  } catch (error) {
    return {
      status: false,
      result: error
    }
  }
};

export const updateSessionAndCookies = async () => {
  try {
    const response = await axios({
      method: REQUEST_TYPES.POST,
      url: `${authURl}${apiPath?.UPDATE_REFRESH_TOKENS_N_COOCKIES}`,
      withCredentials: true,
      data:{
        refresh_token : JSON.parse(getStorageValue(localStorageNames?.loginDetails))?.refresh_token,
        appType : app_type,
        deviceType : deviceType,
      }
    });
    return response;
  } catch (error) {
    return {
      status: false,
      result: error
    }
  }
};

export const verifyToken = async () => {
  try {
    const response = await axios({
      method: REQUEST_TYPES.GET,
      url: `${authBaseURL}${apiPath?.VERIFY_TOKENS}`,
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return {
      status: false,
      result: error
    }
  }
};