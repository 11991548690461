import React from "react";
import "./Profile.css";
import PopUp from "../../../shared/components/PopUp";
import UserStats from "./UserStats";
import UserHistory from "./UserHistory";
import { useState, useEffect } from "react";
import { getTrackingTimeLine } from "../services/ProfileServices";
import { localStorageNames, apiResponseCodes, invalidToken, POP_TYPES } from "../../../shared/utils/Constants";
import { getStorageValue, setStorageValue, signOut } from "../../../shared/utils/common";
import { useTranslation } from "react-i18next";

function Profile() {
  const [isModelOpen, setIsModelOpen] = useState(true);
  const [tableData, setTableData] = useState([]);
  const { t } = useTranslation();
  const [popType, setPopType] = useState<string | "">(POP_TYPES.LOADER);
  const [recCount, setRecordCount] = useState<number>(0);
  const [sessionsCoordinates, setSessionsCoordinates] = useState()
  const [userProfile, setUserprofile] = useState<any>({});
  const [userPreferences, setUserPreferences] = useState<any>({});
  const [refreshData, setRefreshData] = React.useState<boolean | false>(false);
  const [data, setData] = React.useState<string | "">("");
  const [session, setSession] = React.useState();
  const [loggedIn] = React.useState(getStorageValue(localStorageNames?.loggedIn));
  const pageSize = 1;  
  const [userName, setUserName] = useState("");

  useEffect(() => {    
    if (loggedIn) {
      setStorageValue(localStorageNames?.currentPage, t("profile_text"))
      const loggedInUserMobile = JSON.parse(getStorageValue(localStorageNames.loginDetails))?.mobileNo;
      getTrackingTimeLine(loggedInUserMobile, pageSize).then((res) => {
        if (res.status) {
          setPopType("");
          setTableData(res?.result?.timeline);
          setUserprofile(res?.result?.userProfile);
          setUserPreferences(res?.result?.userPreference);
          setRecordCount(res?.result?.session[0]?.sessionCount);
          setSession(res?.result?.session[0]);
          setSessionsCoordinates(res?.result?.totalCoordinates);
          setUserName(res?.result?.userPreference?.userName);
        }
        else if (res.result.response.status === apiResponseCodes.invalidRequest &&
                  res.result.response.data === invalidToken) {
          setPopType(POP_TYPES.ERROR);
          setData(t("authentication_error_msg"));
          setTimeout(() => {
            signOut();            
          }, 2000);
        } 
        else {
          setPopType("");
        }
      });
    }
  }, [refreshData]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    setIsModelOpen(false);
  }

  return (
    <div className="profile_container">
      <UserStats userProfile={userProfile} userPreference={userPreferences} session={session} sessionsCoordinates={sessionsCoordinates}/>
      <UserHistory tableData={tableData} userProfile={userProfile} totalRecords={recCount} refreshData={setRefreshData} userName={userName}/>

      {/* use types--  error, download, loader */}
      <PopUp
        isModelOpen={popType && isModelOpen}
        type={popType}
        handleClose={handleClose}
        message={data}
        setData={setData}
      />
    </div>
  );
}

export default Profile;
