import React, { ReactNode } from "react";
import StatusMessage from "./statusMessage";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: any;  
}

class ErrorBoundary extends React.Component<Props, State> {
  search = window.location.search;
  params = new URLSearchParams(this.search);
  debugMode = this.params.get("debugger");
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error): State {
    return {
      hasError: true,
      error
    };
  }

  componentDidCatch(error, errorInfo): void {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (this.debugMode && this.debugMode === "on") ? 
        <div><span>{this.state.error.message}</span></div>
        : <StatusMessage />
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
