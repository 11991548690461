import React from "react";
import { styled, Stack } from "@mui/material";
import { t } from "i18next";
import errorImage from "../images/warning.svg"
import { pageNavPaths } from "../utils/Config";
import { colorCodes } from "../styles/styles.config";

const StyledStack = styled(Stack)`
  justify-content: center;
  align-items: center;
  height: 73vh;
`;

const StyledH1 = styled("h1")`
  font-size: 5rem;
  margin: 0.5rem 0 0 0;
`;

const StyledText = styled("p")`
  font-size: 2rem;
`;

const StatusImage = styled("img")`
  max-width: 100%;
  max-height: 20vh;
`;

const StyledButton = styled("button")`
  background-color: ${colorCodes.salametti};
  color: ${colorCodes.whiteSmoke};
  border-radius: 18px 18px 18px 18px;
  border: none;
  font-size: 15px;
  font-weight: 600;
  padding: 0.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
`;

interface Props {
  statusCode?: number;
  message?: string;
}

const StatusMessage: React.FC<Props> = ({ statusCode, message }) => {
  let statusMessage: string;
  let statusImage: string;

  switch (statusCode) {
  case 100:
    statusMessage = t("session_expired");
    statusImage = errorImage;
    break;
  case 101:
    statusMessage = t("tracking_not_found");
    statusImage = errorImage;
    break;
  case 404:
    statusMessage = t("page_not_found_text");
    statusImage = errorImage;
    break;
  case 403:
    statusMessage = t("you_dont_have_access_text");
    statusImage = errorImage;
    break;
  default:
    statusMessage = t("something_went_wrong");
    statusImage = errorImage;
  }

  if(message){
    statusMessage = message
  }

  const goToHome = ()=>{
    window.location.href = window.location.origin.toString() + pageNavPaths.home;
  }

  return (
    <StyledStack>
      <StatusImage src={statusImage} alt={statusMessage} />
      { (statusCode < 0) &&
        <StyledH1>Oops!</StyledH1>
      }
      <StyledText>{statusMessage}</StyledText>
      <StyledButton onClick={goToHome}>{t("goto_home_text")}</StyledButton>
    </StyledStack>
  );
};

export default StatusMessage;
