import { getAxiosHeaders, requestMaker } from "../../../shared/providers/requestMaker";
import {REQUEST_TYPES, rowsPerPage, URL_PATHS} from "../../../shared/utils/Constants"
import {apiPath} from "../../../shared/utils/apiConfig"
import { serverTrackingURL } from "../../../shared/utils/Config";

export const getUserHistory = async () => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.GET,
    url: URL_PATHS?.GET_TRACKING_HISTORY,
    headers: getAxiosHeaders(false, true, false),
  });
  return response;
};
 
export const getTrackingTimeLine = async (userId: string, size: number) => {
  const response = await requestMaker({
    method: REQUEST_TYPES.GET,
    url: `${serverTrackingURL}${apiPath.TRACKING_TIME_LINE}${userId}?page=${size}&limit=${rowsPerPage}`,
    headers: getAxiosHeaders(false, true, false),
  });
  return response;
};

export const deleteSessions = async (data) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.PUT,
    url: `${serverTrackingURL}${apiPath?.DELETE_SESSION}`,
    data: data,
    headers: getAxiosHeaders(false, true, false),
  });
  return response;
};

export const editSessions = async (data) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.PUT,
    url: `${serverTrackingURL}${apiPath?.EDIT_SESSION}`,
    headers: getAxiosHeaders(false, true, false),
    data: data
  });
  return response;
};

export const getTrackingSession = async (trackingId) => {
  const response = await requestMaker({
    method: REQUEST_TYPES.GET,
    url: `${serverTrackingURL}${apiPath.GET_SESSION_DATA}${trackingId}`,
    headers: getAxiosHeaders(false, true, false),
  });
  return response;
};

