import i18next from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const supportedLngs = ["bg", "bs", "cs", "da", "de", "el", "en", "es", "et", "fi", "fr", "frca", "hr", "hu", "it", "lt", "lv", "nb", "nl", "pl", "pt", "ro", "ru", "sk", "sl", "sr", "sv", "zh-rTW"];

i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    detection: {
      checkWhitelist: true
    },

    whitelist: supportedLngs,
    interpolation: {
      escapeValue: false
    },
  })