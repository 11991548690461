import * as React from "react";
import "./Header.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Logo from "../../shared/icons/Logo.svg";
import MenuList from "@mui/material/MenuList";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popper";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Fab from "@mui/material/Fab";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material";
import Notification_Icon from "../../shared/icons/Notification_Icon.svg";
import { useTranslation } from "react-i18next";
import { colorCodes, fontFamily, fontWeight } from "../styles/styles.config";
import { pageNavPaths } from "../utils/Config";
import ListItemIcon from "@mui/material/ListItemIcon";
import MobileMenu from "./MobileMenu";
import MobileNotifications from "./MobileNotifications";
import { apiResponseCodes, invalidToken, localStorageNames, POP_TYPES } from "../utils/Constants";
import { getStorageValue, setStorageValue, getUTCDateTime, signOut } from "../utils/common";
import { getNotifications, readNotifications } from "../../pages/liveTracking/services/TrackingServices";
import Login from "../../pages/authentication/components/Login";
import PopUp from "./PopUp";
import { isNil } from "lodash";

let user_menu_expanded = false;
const StyledNotificationIconButton = styled(IconButton)`
    display: block; 
    order: 2;                                
    margin-left: auto;               
    margin-top: 35px;
    margin-bottom: auto;         
     
    @media (max-width: 900px) {
      display: none;        
  }   
`;

const StyledNotificationButtonForMobile = styled(IconButton)`
    display: block; 
    order: 2;
    margin-left: auto;
    margin-top: 2rem;
    margin-bottom: auto;
     
    @media (min-width: 900px) {
      display: none;
    } 
    @media (min-width: 321px) {
      margin-right: 8px;
    }    
`;

const StyledMenuIconButton = styled(IconButton)` 
    display: block;  
    order: 3;    
    margin-top: 1rem;
    margin-right: 3rem;  
    height: 45px;            

    @media (min-width: 900px) {
        display: none;        
    }
`;

const StyledFabButton = styled(Fab)`
    display: flex;        
    box-sizing: border-box;
    width: 94px;
    height: 40px;    
    font-family: ${fontFamily.Roboto};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    text-transform: none;
    border: 1px solid ${colorCodes.black};
    border-radius: 40px;
    background: ${colorCodes.white};
    box-shadow: none;      
    order: 3;                      
    margin-right: 7rem; 
    margin-top: 39px;
    margin-bottom: auto;
    white-space: normal;

    &.MuiButtonBase-root-MuiFab-root {        
        disableRipple: true; 
      }
    @media (max-width: 900px) {
      margin-right: 0.9rem;
      margin-top:2.2rem;
    }
`;

const StyledButton = styled(Button)`  
    display: block; 
    min-width: 170px;
    height: 40px;
    font-family: ${fontFamily.Roboto};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    text-transform: none;
    text-align: center;
    order: 3;
    margin-right: 2.5rem;
    margin-top: 39px;
    margin-bottom: auto;
    color: ${colorCodes.black};
    background-color: ${colorCodes.whiteSmoke};
    borderTopLeftRadius: ${user_menu_expanded} ? 10px : 0px;
    borderTopRightRadius: ${user_menu_expanded} ? 10px : 0px;
    borderBottomLeftRadius: 0;
    borderBottomRightRadius: 0;
    box-shadow: none;        

    @media (max-width: 900px) {
        display: none;
    }
`;

const StyledArrowIcon = styled(KeyboardArrowDownIcon)`
    color: ${colorCodes.salametti};
    height: 14px; 
    fontSize: large;
`;

const StyledPopper = styled(Popover)`  
    display: block;
    width: 169px;
    height: 30px;
    box-shadow: 0 !important;

    @media (max-width: 900px) {
        display: none;
    }
`;

const StyledUserMenuItem = styled(MenuItem)`
    font-family: ${fontFamily.Roboto};
    font-style: normal;
    font-weight: 500;    
    font-size: 14px;
    line-height: 16px;
    text-transform: none;  
    white-space: break-spaces;  
`;

const StyledNotificationBox = styled(Box)`
  backgroundColor: ${colorCodes.white},
  color: ${colorCodes.black},  
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const StyledNotificationMenu = styled(Menu)`  
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const StyledNotificationItem = styled(MenuItem)`
    width: 240px;
    top: -7px;
    white-space: normal;
`;

const StyledNoNotifications = styled(MenuItem)`
    display: flex;
    width: 240px;              
    white-space: normal;
    color: #979797;
    background-color: ${colorCodes.white};
    font-family: ${fontFamily.Roboto};
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-align: center;
    justify-content: center;
    justify-items: center;
`;

const StyledPaper = styled(Paper)`
    box-shadow: 0 !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
`;

const StyledRoundedIcon = styled(FiberManualRecordRoundedIcon)`
    width: 7px;
    height: 7px;
    color: ${colorCodes.black};
    margin-left: 1px;
    margin-right: auto;      
`;

const StyledNotificationIcon = styled(MenuItem)`  
    position: absolute;
    width: 240px;
    top: -5.75px;
    height: 45px;
    background: ${colorCodes.whiteSmoke};      
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    "&:hover": {background: ${colorCodes.whiteSmoke}};
    whiteSpace: normal;
    padding-right: 1.5rem;
`;

const StyledNotificationText = styled(Typography)`
    width: 60%;
    font-family: ${fontFamily.Roboto};
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    margin-left: -20px;
    margin-right: auto;
    white-space: normal;
    color: ${colorCodes.black};
`;

const StyledNotificationTime = styled(Typography)`
    width: 40%;
    font-family: ${fontFamily.Roboto};
    font-style: normal;    
    font-size: 10px;
    line-height: 12px;
    margin-left: auto;
    margin-right: -10px;
    color: ${colorCodes.steelWool};
`;


export default function Header() {

  const useToggleNotification = (initialState) => {
    const [toggleValue, setToggleValue] = React.useState(initialState);

    const toggler = () => {
      setToggleValue(!toggleValue);
      setNotificationReadCount(notificationReadCount + 1);
      if (toggleValue === true && notificationReadCount === 2) {
        setStorageValue(localStorageNames?.notificationRead, true);
      }
    };
    return [toggleValue, toggler]
  };

  const [openUserMenu, setUserMenuOpen] = React.useState<boolean | false>(false);
  const userMenuAnchorRef = React.useRef(null);
  const [openNotificationList] = React.useState<boolean | false>(false);
  const [notificationListAnchor, setNotificationListAnchor] = React.useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [notificationList, setNotificationList] = React.useState([]);
  const [notificationCount, setNotificationCount] = React.useState(0);
  const [viewed, setViewed] = React.useState<boolean | false>(false);
  const [openMobileMenu, setOpenMobileMenu] = React.useState<boolean | false>(false);
  const [openMobileNotifications, setOpenMobileNotifications] = useToggleNotification(false);
  const [notificationReadCount, setNotificationReadCount] = React.useState(1);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [isModelOpen, setIsModelOpen] = React.useState(true);
  const [popType, setPopType] = React.useState("");
  const [data, setData] = React.useState("");
  const [loggedIn, setLoggedIn] = React.useState(getStorageValue(localStorageNames?.loggedIn));
  const [refreshNotification, setRefreshNotification] = React.useState(false);
  const [userName, setUserName] = React.useState("");
  const nameArray = userName && userName?.split(" ");
  const firstInitial = nameArray && nameArray[0]?.charAt(0);
  const lastInitial = nameArray && nameArray?.length > 1 ? nameArray[nameArray?.length - 1]?.charAt(0) : "";
  const initials = firstInitial + lastInitial;

  const handleSignIn = () => {
    if (!isNil(getStorageValue(localStorageNames?.loggedIn)))
    {
      setLoggedIn(getStorageValue(localStorageNames?.loggedIn));
      window.location.href = window.location.origin + pageNavPaths?.profile;
    }
    if (isNil(getStorageValue(localStorageNames?.loggedIn)))
    {
      window.location.href = `${process.env.REACT_APP_BULLITT_LOGIN_PAGE_URL}/login?redirect=${window.location.origin}`
      // setOpenLogin(true);
    }
  }

  const handleSignInClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenLogin(false);
  }

  const handleSignOut = () => {
    signOut();
  }

  const handleViewProfile = () => {
    handleUserMenu();
    navigate(pageNavPaths?.profile);
    setStorageValue(localStorageNames?.currentPage, t("profile_text"));
  }

  const navigateToHome = () => {
    if (loggedIn) {
      navigate(pageNavPaths?.profile)
      setStorageValue(localStorageNames?.currentPage, t("profile_text"))
    } else {
      window?.location?.reload();
    }
  }

  const handlePreferences = () => {
    handleUserMenu();
    navigate(pageNavPaths?.preferences);
    setStorageValue(localStorageNames?.currentPage, t("menu_item_preferences"))
  }

  const handleContacts = () => {
    handleUserMenu();
    navigate(pageNavPaths?.contacts);
    setStorageValue(localStorageNames?.currentPage, t("menu_item_contacts"))
  }

  const handleUserMenu = () => {
    setUserMenuOpen((prevOpenUserMenu) => !prevOpenUserMenu);
    user_menu_expanded = openUserMenu;
  }

  const handleUserMenuClose = (event) => {
    if (userMenuAnchorRef?.current && userMenuAnchorRef?.current?.contains(event?.target)) {
      return;
    }
    setUserMenuOpen(false);
  }

  function handleUserMenuListKeyDown(event) {
    if (event?.key === "Tab") {
      event?.preventDefault();
      setUserMenuOpen(false);
    } else if (event?.key === "Escape") {
      setUserMenuOpen(false);
    }
  }

  const handleNotificationList = (e) => {
    setNotificationListAnchor(e.currentTarget);
    setStorageValue(localStorageNames?.notificationRead, true);
  }

  const handleNotificationListClose = () => {
    setNotificationListAnchor(null);
    const loggedInUserMobile = JSON.parse(getStorageValue(localStorageNames?.loginDetails))?.mobileNo;
    if (!viewed) {
      readNotifications(loggedInUserMobile).then((res) => {
        if (res && res?.status) {
          setViewed(res?.result?.acknowledged);
        }
      })
    }
    setNotificationCount(0);
  }

  const handleNotificationClick = (e, notification) => {
    handleNotificationListClose()
    navigate(`${pageNavPaths?.liveTracking}?id=${notification?.trackingId}`);
    setStorageValue(localStorageNames?.currentPage, "")
  }

  const handleMobileMenu = () => {
    setOpenMobileMenu(true);
  }

  const handleMobileMenuClose = (value) => {
    setOpenMobileMenu(value);
  }

  const handleMobileNotificationsClose = (value) => {
    setOpenMobileNotifications(value);
  }

  const openNotifications = Boolean(notificationListAnchor);

  React.useEffect(() => {
    const userDetails = JSON.parse(getStorageValue(localStorageNames?.loginDetails));
    setUserName(userDetails?.userName);
  }, []);

  React.useEffect(() => {
    if (loggedIn) {
      const loggedInUserMobile = JSON.parse(getStorageValue(localStorageNames?.loginDetails))?.mobileNo;      
      getNotifications(loggedInUserMobile).then((res) => {
        if (res && res?.status) {
          const pushNotifications = [];
          const UnNotifications = [];
          const data = res?.result;
          data?.map((notification) => {
            notification?.unreadNotification?.map((data) => {
              pushNotifications.push({...data, sessionName:notification?.sessionName, trackingId:notification?.trackingId});
              if(!data.read) {
                UnNotifications.push(data);
              }
              (pushNotifications).sort((a, b) => {
                if (a.time > b.time) { return -1; }
                return 0;
              });
              setNotificationList(pushNotifications);
              setNotificationCount(UnNotifications?.length);
            })
          })
        }
        else if (
          res.result.response.status === apiResponseCodes.invalidRequest &&
          res.result.response.data === invalidToken) {
          setIsModelOpen(true);
          setPopType(POP_TYPES.ERROR);
          setData(t("authentication_error_msg"));
          setTimeout(() => {
            signOut();            
          }, 2000);
        }
      })
    }
  }, [refreshNotification]);
  
  React.useEffect(() => {
    const reloadNotification = setInterval(() => {
      setRefreshNotification((current: boolean) => !current);
    }, 180000);
    
    return () => {
      clearInterval(reloadNotification)
    };
  }, []);

  const handleClose = () => {
    setIsModelOpen(false);
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpenUserMenu = React.useRef(openUserMenu);
  React.useEffect(() => {
    if (prevOpenUserMenu?.current === true && openUserMenu === false) {
      userMenuAnchorRef?.current?.focus();
    }
    prevOpenUserMenu.current = openUserMenu;
  }, [openUserMenu]);

  // return focus to the button when we transitioned from !open -> open
  const prevOpenNotificationList = React.useRef(openNotificationList);
  React.useEffect(() => {
    if (prevOpenNotificationList?.current === true && openNotificationList === false) {
      notificationListAnchor?.current?.focus();
    }
    prevOpenNotificationList.current = openNotificationList;
  }, [openNotificationList]);

  return (
    <>
      <Box className="header-class">
        <img className="img-class" src={Logo} alt="logo" width="197" height="84" onClick={navigateToHome} />
        <StyledNotificationButtonForMobile
          size="large"
          color="primary"
          disableRipple
          disabled={(getStorageValue(localStorageNames?.loggedIn) !== true)}
          onClick={setOpenMobileNotifications}
        >
          {(getStorageValue(localStorageNames?.loggedIn) === true) &&
            <Badge
              badgeContent={viewed ? 0 : notificationCount}
              max={99}
              sx={{
                "& .MuiBadge-badge": {
                  color: colorCodes?.white,
                  backgroundColor: colorCodes?.startlingOrange
                }
              }}
            >
              <img
                className="notification-icon-color"
                src={Notification_Icon}
                alt="notifications"
                width="18" height="23"
              />
            </Badge>
          }
          {getStorageValue(localStorageNames?.loggedIn) !== true &&
            <img
              className="notification-disabled-icon-color"
              src={Notification_Icon}
              alt="notifications"
              width="18" height="23"
            />
          }
        </StyledNotificationButtonForMobile>
        {((getStorageValue(localStorageNames?.loggedIn) === true) && userName) &&
          <StyledMenuIconButton
            size='small'
            disableRipple
            onClick={handleMobileMenu}
          >
            <div className="header-menu_img">
              <h2>{initials}</h2>
            </div>
          </StyledMenuIconButton>
        }
        <StyledNotificationIconButton
          size="large"
          color="primary"
          onClick={handleNotificationList}
          disableRipple
          disabled={(getStorageValue(localStorageNames?.loggedIn) !== true)}
        >
          {((getStorageValue(localStorageNames?.loggedIn) === true) && !openNotificationList) &&
            <Badge
              badgeContent={(getStorageValue(localStorageNames?.loggedIn) == true) && viewed ? 0 : notificationCount}
              max={99}
              sx={{
                "& .MuiBadge-badge": {
                  color: colorCodes?.white,
                  backgroundColor: colorCodes?.startlingOrange
                }
              }}
            >
              <img
                className="notification-icon-color"
                src={Notification_Icon}
                alt="notifications"
                width="18" height="23"
              />
            </Badge>
          }
          {getStorageValue(localStorageNames?.loggedIn) !== true &&
            <img
              className="notification-disabled-icon-color"
              src={Notification_Icon}
              alt="notifications"
              width="18" height="23"
            />
          }
        </StyledNotificationIconButton>
        {getStorageValue(localStorageNames?.loggedIn) !== true &&
          <StyledFabButton
            variant="extended"
            disableTouchRipple={true}
            disableRipple={true}
            onClick={handleSignIn}
          >
            {t("login_button_text")}
          </StyledFabButton>
        }
        {getStorageValue(localStorageNames?.loggedIn) === true &&
          <>
            <StyledButton
              variant="contained"
              ref={userMenuAnchorRef}
              id="composition-button"
              aria-controls={openUserMenu ? "composition-menu" : undefined}
              aria-expanded={openUserMenu ? "true" : undefined}
              aria-haspopup="true"
              size="small"
              onClick={handleUserMenu}
              disableElevation
              disableTouchRipple={true}
              sx={{
                "&:hover": { backgroundColor: colorCodes?.whiteSmoke }
              }}
            >
              {JSON.parse(getStorageValue(localStorageNames?.loginDetails))?.userName}&nbsp;
              <StyledArrowIcon />
            </StyledButton>
            <StyledPopper
              open={openUserMenu}
              anchorEl={userMenuAnchorRef?.current}
              role={undefined}
              placement="bottom-start"
              transition
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <StyledPaper>
                    <ClickAwayListener onClickAway={handleUserMenuClose}>
                      <MenuList
                        autoFocusItem={openUserMenu}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleUserMenuListKeyDown}
                        dense
                      >
                        <StyledUserMenuItem
                          sx={{
                            backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("profile_text")) ? colorCodes?.tango : "transparent",
                            color: (getStorageValue(localStorageNames?.currentPage) === t("profile_text")) ? colorCodes?.white : colorCodes?.black,
                            "&:hover": {
                              backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("profile_text")) ? colorCodes?.tango : "transparent",
                            }
                          }}
                          onClick={handleViewProfile}
                        >
                          {t("profile_text")}
                        </StyledUserMenuItem>
                        <StyledUserMenuItem
                          sx={{
                            backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_preferences")) ? colorCodes?.tango : "transparent",
                            color: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_preferences")) ? colorCodes?.white : colorCodes?.black,
                            "&:hover": {
                              backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_preferences")) ? colorCodes?.tango : "transparent",
                            }
                          }}
                          onClick={handlePreferences}
                        >
                          {t("menu_item_preferences")}
                        </StyledUserMenuItem>
                        <StyledUserMenuItem
                          sx={{
                            backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_contacts")) ? colorCodes?.tango : "transparent",
                            color: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_contacts")) ? colorCodes?.white : colorCodes?.black,
                            "&:hover": {
                              backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_contacts")) ? colorCodes?.tango : "transparent",
                            }
                          }}
                          onClick={handleContacts}
                        >
                          {t("menu_item_contacts")}
                        </StyledUserMenuItem>
                        <StyledUserMenuItem
                          sx={{
                            backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_signout")) ? colorCodes?.tango : "transparent",
                            color: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_signout")) ? colorCodes?.white : colorCodes?.black,
                            "&:hover": {
                              backgroundColor: (getStorageValue(localStorageNames?.currentPage) === t("menu_item_signout")) ? colorCodes?.tango : "transparent",
                            }
                          }}
                          onClick={(e) => {
                            handleUserMenuClose(e);
                            handleSignOut();
                          }}
                        >
                          {t("menu_item_signout")}
                        </StyledUserMenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </StyledPaper>
                </Grow>
              )}
            </StyledPopper>
          </>
        }

        {(openNotificationList || (getStorageValue(localStorageNames?.loggedIn) === true)) &&
          <>
            <StyledNotificationBox>
              <>
                <StyledNotificationMenu
                  open={openNotifications}
                  anchorEl={notificationListAnchor}
                  onClose={handleNotificationListClose}
                  sx={{
                    ".MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
                      marginTop: "-50px",
                      marginLeft: "-170px",
                      maxHeight: "72vh",
                      overflowX: "hidden",
                      overflowY: "auto"
                    },
                    ".MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper::-webkit-scrollbar": {
                      width: "6px",
                      backgroundColor: "#D9D9D9"
                    },

                    ".MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper::-webkit-scrollbar-thumb": {
                      backgroundColor: "#808080",
                      borderRadius: "10px",
                    }
                  }}
                >
                  <StyledNotificationIcon>
                    <h4>{t("notifications_label")}</h4>
                  </StyledNotificationIcon><br /><br />
                  {notificationList?.length > 0 ?
                    notificationList?.map((notification, index) => {
                      return (
                        <StyledNotificationItem key={index}
                          onClick={(e)=>handleNotificationClick(e,notification)}
                        >
                          <ListItemIcon>
                            <StyledRoundedIcon />
                          </ListItemIcon>
                          &nbsp;&nbsp;
                          <StyledNotificationText
                            sx={{
                              fontWeight: notification.read || viewed ? fontWeight?.read : fontWeight?.unread
                            }}
                          >
                            {notification?.statusCode === 200 ? `${t("tracking_started")} - ${notification?.sessionName}` : `${t("tracking_ended")} - ${notification?.sessionName}`}
                          </StyledNotificationText>
                          <StyledNotificationTime
                            sx={{
                              fontWeight: notification.read || viewed ? fontWeight?.read : fontWeight?.unread
                            }}
                          >
                            {getUTCDateTime(notification?.time)}
                          </StyledNotificationTime>
                        </StyledNotificationItem>
                      )
                    }) : <StyledNoNotifications>{t("no_notification_found")}</StyledNoNotifications>
                  }
                </StyledNotificationMenu>
              </>
            </StyledNotificationBox>
          </>
        }
      </Box>
      {openLogin &&
        <Login handleClose={handleSignInClose} />
      }
      {openMobileMenu &&
        <MobileMenu
          isOpen={handleMobileMenuClose}
          handleViewProfile={handleViewProfile}
          handlePreferences={handlePreferences}
          handleContacts={handleContacts}
          handleSignOut={handleSignOut}
        />
      }
      {openMobileNotifications &&
        <MobileNotifications
          notifications={notificationList}
          viewed={viewed}
          isOpen={handleMobileNotificationsClose}
          readNotification={handleNotificationListClose}
        />
      }
      <PopUp
        isModelOpen={popType && isModelOpen}
        type={popType}
        handleClose={handleClose}
        message={data}
        setData={setData}
      />
    </>
  );
}