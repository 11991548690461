import { t } from "i18next";
import React from "react";
import Loader_img from "../icons/loading_img.svg";

export default function LoaderPop({ data }) {
  return (
    <div className="popup" style={{ alignItems: "center" }}>
      <h3 className="popup_title">{data?.title}</h3>

      <p className="popup_message"> {data?.message} </p>

      <img src={Loader_img} alt={t("loader_text")} className="popup_loader_img" />
    </div>
  );
}
