import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { deleteContactList } from "../services/ContactServices";
import { apiResponseCodes, invalidToken, localStorageNames, POP_TYPES } from "../../../shared/utils/Constants";
import { t } from "i18next";
import { getStorageValue, signOut } from "../../../shared/utils/common";
import { styled } from "@mui/material";
import { colorCodes } from "../../../shared/styles/styles.config";

function DeleteContact(props) {

  const OrangeButton = styled(Button)`
    color: ${colorCodes.tango};
  `;

  const showPopUp = (msg: string) => {
    props.setPopType(msg);
    props.setIsModelOpen(true);
  }
  const handleDelete = () => {
    const loggedInUserMobile = JSON.parse(getStorageValue(localStorageNames?.loginDetails))?.mobileNo;

    deleteContactList(props?.deleteContact, loggedInUserMobile).then((res) => {
      if (res && res?.result?.code === apiResponseCodes?.success) {
        props?.handleClose(false);
        showPopUp(POP_TYPES?.SUCCESS);
        props?.setData(res?.result?.data);
        setTimeout(() => {
          props?.setNewData((current) => !current);
        }, 2000);
      } 
      else if (res.result.response.status === apiResponseCodes.invalidRequest &&
              res.result.response.data === invalidToken) {
        props?.handleClose(false);
        showPopUp(POP_TYPES?.ERROR);
        props?.setData(t("authentication_error_msg"));
        setTimeout(() => {
          signOut();
        }, 2000);
      }
      else {
        props?.handleClose(false);
        showPopUp(POP_TYPES?.ERROR);
        props?.setData(t("delete_error_msg"));
      }
    })
  }

  return (
    <div>
      <Dialog
        open={props?.isModelOpen}
        onClose={props?.handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              minWidth: "25%",
              borderRadius: "10px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {t("delete_contact_text")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("delete_contact_confirmation_text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OrangeButton onClick={() => handleDelete()} autoFocus>{t("yes_button_text")}</OrangeButton>
          <OrangeButton onClick={props?.handleClose}>{t("no_button_text")}</OrangeButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteContact;