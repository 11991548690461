import { getAxiosHeaders, requestMaker } from "../../../shared/providers/requestMaker";
import { apiPath } from "../../../shared/utils/apiConfig";
import { serverTrackingURL } from "../../../shared/utils/Config";
import { REQUEST_TYPES } from "../../../shared/utils/Constants";

export const getTrackingDetails = async (fcmData: object) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.POST,
    url: `${serverTrackingURL}${apiPath?.TRACKING_DETAILS}`,
    data: fcmData
  });
  return response;
};

export const getNotifications = async (userId: string) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.GET,
    url: `${serverTrackingURL}${apiPath?.GET_NOTIFICATIONS}${userId}`,
    headers: getAxiosHeaders(false, true, false),
  });

  return response;
};

export const readNotifications = async (userId: string) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.PUT,
    url: `${serverTrackingURL}${apiPath?.READ_NOTIFICATIONS}${userId}`,
    headers: getAxiosHeaders(false, true, false),
  });

  return response;
};