import React from "react";
import { useTranslation } from "react-i18next";

function DownloadPop({ data, closeModel }) {
  const { t } = useTranslation();

  return (
    <div className="popup" style={{ alignItems: "center" }}>
      <h3 className="popup_title">{data.title}</h3>
      <p className="popup_message">
        {data.message}
      </p>

      <p className="download_success">{t("file_download_success_text")}</p>

      <button className="dismiss_btn" onClick={() => closeModel()}>
        {t("dismiss_button_text")}
      </button>
    </div>
  );
}

export default DownloadPop;
