import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog } from "@mui/material";

interface sessionExpiredProps {
  isModelOpen?: boolean;
  closeModel?: any;
}

function SessionExpiredPop(props : sessionExpiredProps) {
  const { t } = useTranslation();

  const handleClose = ()=>{
    props.closeModel()
  }

  return (
    <Dialog 
      open={props.isModelOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "20rem",
            borderRadius: "10px",
          },
        },
      }}
    >
      <div className="popup">
        <h3 className="popup_title">
          {t("session_expired")}
        </h3>
        <p className="popup_message">{t("session_expired_content")}</p>

        <button className="dismiss_btn" onClick={() => props.closeModel()}>
          {t("ok")}
        </button>
      </div>
    </Dialog>
  );
}

export default SessionExpiredPop;
