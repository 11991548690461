//  Color Hex Codes
export const colorCodes = {
  white: "#FFFFFF",
  black: "#000000",
  brown: "#979797",
  darkCharcoal: "#333333",
  whiteSmoke: "#F5F5F5",
  salametti: "#E3602C",
  steelWool: "#777777",
  startlingOrange: "#E3612C",
  tango: "#E66C2B",
  dustyGray: "#979797",
  silver: "#BDBDBD",
  ebonyClay: "#252B42",
  alto: "#D9D9D9",
  gray: "#808080",
  bunker: "#0F1419",
  burntSienna: "#E77446",
  karry: "#FFE8D8" ,
  errorText : "#d32f2f"
};

export const fontFamily = {
  Roboto: "Roboto"
};

export const fontWeight = {
  read:  "300 !important",
  unread: "500 !important"
}