import React from "react";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import styled from "@emotion/styled";
import ShareTracking from "../../pages/liveTracking/components/ShareTracking";
import { colorCodes, fontFamily } from "../styles/styles.config";
import { sessionType } from "../utils/Constants";

function SharePop({ closeModel }) {
  const { t } = useTranslation();

  const StyledContainer = styled(Stack)`
    display: flex;
    flex-direction: row;    
    justify-content: space-between;
    @media (max-width: 900px) {
      flex-direction: column;
    }
  `;

  const StyledShareHolder = styled(Stack)`
    & div{
      @media (max-width: 900px) {
        width: auto;
      }
      & div{
        @media (max-width: 900px) {
          display: flex;
        }
        & p,h6{
          display: block;
        }
        & h6{
          margin: auto;
        }
        & div{
        width: 100%;
       }
     }
     & .top_dark_color{
      display: none;
     }
    }
  `;

  const StyledButton = styled("button")`
    border: none;
    background-color: ${colorCodes.white};
    font-family: ${fontFamily.Roboto};
    font-size: 12px;
    font-weight: 500;
    font-style: normal; 
    line-height: 14px;
    color: ${colorCodes.black};
    padding:  0 0 1rem 0;
    cursor: pointer;       
  `

  return (    
    <Stack>
      <StyledContainer>        
        <StyledShareHolder>
          <ShareTracking sessionTrackingType={sessionType?.public} isPopUp={true}/>
        </StyledShareHolder>
      </StyledContainer >
      <StyledButton onClick={() => closeModel()}>
        {t("dismiss_button_text")}
      </StyledButton>
    </Stack>
  );
}

export default SharePop;
