import React from "react";
import "./ShareTracking.css";
import { Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import EmailIcon from "../../../shared/icons/Email_Black.svg";
import FbIcon from "../../../shared/icons/Facebook_Black.svg";
import TwitterIcon from "../../../shared/icons/Twitter_Black.svg";
import WebIcon from "../../../shared/icons/Web_Black.svg";
import { useTranslation } from "react-i18next";
import { colorCodes, fontFamily } from "../../../shared/styles/styles.config";
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from "react-share";
import { liveTrackingContext } from "./LiveTracking";
import Tooltip from "@mui/material/Tooltip";
import { sessionType, URL_PATHS } from "../../../shared/utils/Constants";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { sessionTracking } from "../../../shared/components/PopUp";

const StyledStackContainer = styled(Stack)`
  font-family: ${fontFamily.Roboto};
  color: ${colorCodes.darkCharcoal};
  @media (max-width: 900px) {
    width: 90vw;
  }
`;

const StyledHead = styled(Stack)`
  background-color: ${colorCodes.whiteSmoke};
  height: 40px;
  border-radius: 10px 10px 0 0;
  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledTitleText = styled(Typography)`
  padding-bottom: 1rem;
  font-size: 24px;
  font-weight: 700;
  font-family: ${fontFamily.Roboto};
  color: ${colorCodes.darkCharcoal};
  @media (max-width: 900px) {
    display: none;
  }
`;
const StyledText = styled(Typography)`
  padding-bottom: 1rem;
  font-size: 16px;
  font-weight: 400px;
  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledIconsWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;

`;

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colorCodes.startlingOrange,
          color: colorCodes.white,
          border: `1px solid ${colorCodes.startlingOrange}`,
          maxWidth: "5.5rem"
        },
      },
    },
  },
});

export default function ShareTracking({sessionTrackingType, isPopUp = false}) {
  const { t } = useTranslation();    
  const liveTrackingSession = React.useContext(liveTrackingContext);
  const [isCopied, setIsCopied] = React.useState(false);
  const [tooltipText, setTooltipText] = React.useState(t("webShare_tooltip_text"));

  React.useEffect(() => {
    if (isCopied) {
      setTooltipText(t("copied_text"));
    }
  }, [isCopied]);

  const appURL = window?.location?.origin;  
  const liveSessionURL = liveTrackingSession?.length > 0 ? liveTrackingSession : 
    appURL.concat(URL_PATHS.LIVE_TRACKING, React.useContext(sessionTracking));  

  const handleCopy = async () => {
    try {
      await navigator?.clipboard?.writeText(liveSessionURL);
      setIsCopied(true);      
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <StyledStackContainer sx={{width:`${isPopUp ? "25rem" : "30vw"}`}}>
      {/* For Dark top */}
      <StyledHead className="top_dark_color"></StyledHead>
      <Stack
        sx={{
          padding: "1rem 2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-around",
          "@media (max-width: 900px)": {
            padding:"0rem 1rem 0 1rem",
            marginBottom:"20px"
          }
        }}
      >
        { (sessionTrackingType === sessionType?.public) && 
          <>
            <StyledTitleText variant="h6">{t("share_text")}</StyledTitleText>
            <StyledText align="left">
              {t("share_session_text")}
            </StyledText>
            <StyledIconsWrapper className="share-icons">
              <ThemeProvider theme={theme}>                 
                <Tooltip title={t("email_tooltip_text")} placement="top" arrow>
                  <EmailShareButton
                    subject="myLiveTracking"
                    body="Follow my Bullitt Satellite Messenger tracking session here: "
                    separator=""
                    url={liveSessionURL}
                    className="social-icons"
                  >
                    <img src={EmailIcon} className="share-icon-style" alt={t("email_text")} />
                  </EmailShareButton>
                </Tooltip>  
                <Tooltip title={t("facebook_tooltip_text")} placement="top" arrow>
                  <FacebookShareButton                                         
                    // hashtag="#HeyCheckOutMyLatestPost!"
                    quote={`Hey, checkout my latest post! ${liveSessionURL}`}
                    url={liveSessionURL}                    
                    className="social-icons"
                  >
                    <img src={FbIcon} className="share-icon-style" alt={t("facebook_logo_text")} />
                  </FacebookShareButton>
                </Tooltip>
                <Tooltip title={t("twitter_tooltip_text")} placement="top" arrow>
                  {/* <TwitterShareButton
                    title="Hey, check out my latest post!"            
                    // hashtags={["Bullitt", "Satellite"]}
                    url={liveSessionURL}
                    className="social-icons"
                  >
                    <img src={TwitterIcon} className="share-icon-style" alt={t("twitter_logo_text")} />
                  </TwitterShareButton>                                 */}
                  <a
                    href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                      "Hey, check out my latest post!"
                    )} &url=${encodeURIComponent(liveSessionURL)}`}
                    className="social-icons"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={TwitterIcon}
                      className="share-icon-style"
                      alt={t("twitter_logo_text")}
                    />
                  </a>
                </Tooltip>
                <Tooltip title={tooltipText} placement="top" arrow>
                  <button onClick={handleCopy} className="web-icon">
                    <img className="hand-cursor share-icon-style" src={WebIcon} alt={t("web_logo_text")} />
                  </button>
                </Tooltip>  
              </ThemeProvider>
            </StyledIconsWrapper>
          </>
        }
        { (sessionTrackingType === sessionType?.private) && 
          <>
            <StyledTitleText variant="h6">{t("private_tracking_session")}</StyledTitleText>
            <StyledText align="left">
              {t("private_session_share_text")}
            </StyledText>
          </>
        }
      </Stack>
    </StyledStackContainer>
  );
}
