export const POP_TYPES = {
  ERROR: "error",
  DOWNLOAD: "download",
  LOADER: "loader",
  SHARE: "share",
  SUCCESS: "success",
  EDIT: "edit",
  DELETE:"delete"
};

export const REQUEST_TYPES = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const URL_PATHS = {
  HOME: "/",
  CONTACT: "/contact",
  GET_TRACKING_HISTORY: "/get-tracking-history",
  LIVE_TRACKING: "/livetracking?id="  
};

//  Map view types
export const mapTypes = {
  terrain: "terrain",
  roadmap: "roadmap",
  satellite: "satellite",
  hybrid: "hybrid"
};

// Session Types

export const sessionType = {
  public: "PUBLIC",
  private: "PRIVATE"
}

export const apiResponseCodes = {
  success: "200",
  notFound: "404",
  serverError: "500",
  badRequest: "400",
  noContentFound:204,
  invalidRequest: 401
}

export const trackingType = {
  open: "STARTTRACKING",
  close: "STOPTRACKING",
}

export const preferenceTypes = {
  public: "Public",
  miles: "Miles",
  language: "Language",
  userName: "userName"
}

export const fcmData = {
  type: "SUBSCRIBE", // Get it from props and have a TO DO comment
}

export const localStorageNames  = {
  loginDetails : "loginDetails",
  notificationRead : "notificationRead",
  loggedIn : "loggedIn",
  fcmToken : "fcmToken",
  currentPage : "currentPage",
  language: "language",
  sessionData:"sessionData",  
  mapType:"mapType",
  trackingId:"trackingId",
  isPreferencesFetched:"isPreferencesFetched"
}

export const rowsPerPage = 10;
export const invalidToken = "Invalid authorization token value";

export const app_type = "btp";
export const deviceType = "web";
export const supportLink = "https://support.bullitt.com/";

