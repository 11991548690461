import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { POP_TYPES, apiResponseCodes, invalidToken } from "../../../shared/utils/Constants";
import { t } from "i18next";
import { deleteSessions } from "../services/ProfileServices";
import { colorCodes } from "../../../shared/styles/styles.config";
import { styled } from "@mui/material";
import { signOut } from "../../../shared/utils/common";

function DeleteSession(props) {

  const OrangeButton = styled(Button)`
    color: ${colorCodes.tango};
  `;
  
  const showPopUp = (msg: string) => {
    props.setPopType(msg);
    props.setIsModelOpen(true);
  }
  const handleDelete = () => {
    const isAllSessionUserIdsSame = props?.selectedSessionUserIds?.every((val, i, arr) => val === arr[0])
    const userId =  isAllSessionUserIdsSame ? props?.selectedSessionUserIds[0] : props?.loggedInUserMobile
    const deleteSessionsData = {
      userId: userId,
      trackingIds: props?.selectedSessions?.length > 0 ? props?.selectedSessions : [props?.selectedSessions]
    }
    deleteSessions(deleteSessionsData).then((res) => {
      if (res && res?.status) {
        props.handleClose(false);
        showPopUp(POP_TYPES.SUCCESS);
        props?.clearPrevSelectedSession();
        props.setData(t("delete_session_success_msg"));
        props.setNewData((current: boolean) => !current);
      } 
      else if (res.result.response.status === apiResponseCodes.invalidRequest &&
              res.result.response.data === invalidToken) {
        showPopUp(POP_TYPES.ERROR);
        props.setData(t("authentication_error_msg"));
        setTimeout(() => {
          signOut();
        }, 2000);
      } 
      else {
        props.handleClose(false);
        showPopUp(POP_TYPES?.ERROR);
        props.setData(res?.result?.message);
      }
    })
  }

  return (
    <div>
      <Dialog
        open={props?.isModelOpen}
        onClose={props?.handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              minWidth: "20%",
              borderRadius: "10px",
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {t("delete_session_text")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props?.selectedSessions?.length > 1 ? t("delete_multiple_sesssions_confirmation_text") : t("delete_sesssion_confirmation_text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <OrangeButton onClick={() => handleDelete()} autoFocus>{t("yes_button_text")}</OrangeButton>
          <OrangeButton onClick={props?.handleClose}>{t("no_button_text")}</OrangeButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteSession;