import * as React from "react";
import Map from "../../../shared/components/GoogleMapsView";
import Main from "./Main";
import { onMessageListener, requestForToken } from "../../../shared/components/FirebaseInit";
import { fcmData, localStorageNames, POP_TYPES, trackingType } from "../../../shared/utils/Constants";
import { getTrackingDetails } from "../services/TrackingServices";
import { useLocation } from "react-router";
import { getBrowserName, getStorageValue, getUTCDateTime, isIosDevice, setStorageValue, sortCoordinatesBasedOnTrackTime } from "../../../shared/utils/common";
import PopUp from "../../../shared/components/PopUp";
import StatusMessage from "../../../shared/components/statusMessage";
import { isEmpty, isNil } from "lodash";
import "./LiveTrackingDetails.css";
import { is_token } from "../../../shared/utils/Config";
import NotificationAlertPop from "../../../shared/components/NotificatonAlertPop";
import { Box } from "@mui/system";
import { Alert, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colorCodes } from "../../../shared/styles/styles.config";
import ErrorBoundary from "../../../shared/components/ErrorBoundary";
import Footer from "../../../shared/components/Footer";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";

export const liveTrackingContext = React.createContext("");

export default function LiveTracking() {
  const [liveTracking, setLiveTracking] = React.useState("");
  const [startPosition, setStartPosition] = React.useState({ lat: 0, lng: 0, trackTime: "" });
  const [centerPosition, setCenterPosition] = React.useState({ lat: 0, lng: 0 });
  const [endPosition, setEndPosition] = React.useState({ lat: 0, lng: 0, trackTime: "" });
  const [polyLines, setPolyLines] = React.useState([{ lat: 0, lng: 0, trackTime: "" }]);
  const [isValidId, setIsValidId] = React.useState(true);
  const [popType, setPopType] = React.useState(POP_TYPES.LOADER);
  const [isModelOpen, setIsModelOpen] = React.useState(true);
  const [data, setData] = React.useState("");
  const [trackingStatusCode, setTrackingStatusCode] = React.useState();
  const [updateLocation, setUpdateLocation] = React.useState(false);
  const [isNotificationAlert, setIsNotificationAlert] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [trackingData, setTrackingData] = React.useState();
  const [lastUpdateSession, setLastUpdateSession] = React.useState("");
  const [frequency, setFrequency] = React.useState(0);
  const [refreshPage, setRefreshPage] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [isUpdateTime, setIsUpdateTime] = React.useState({trackTime: ""});
  const [updatedFcmData, setUpdatedFcmData] = React.useState();
  const [errorMsg, setErrorMsg] = React.useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const id = searchParams.get("id");
  const { t } = useTranslation();

  if (!id) {
    return <StatusMessage />;
  }

  const debugMode = searchParams.get("debugger");

  // Call to get the token form fcm 
  requestForToken();
  const [currentToken] = React.useState(getStorageValue(localStorageNames?.fcmToken));
  const [liveTrackingId, setLiveTrackingId] = React.useState(getStorageValue(localStorageNames?.trackingId));

  const StyledWebBanner = styled("div")`
    display: block;
    font-size: 15px;
    text-align: left;
     
    @media (max-width: 900px) {
      display: none;        
    }   
  `;

  const StyledMobileBanner = styled("div")`
    display: block;
    font-size: 15px;
    text-align: left;
     
    @media (min-width: 900px) {
      display: none;        
    }   
  `;

  const unsubscribeFcmNotifications = async () => {
    try {
      const fcmUnsubscribe = {};
      fcmUnsubscribe["fcmToken"] = !isNil(currentToken) ? currentToken : is_token;
      fcmUnsubscribe["trackingId"] = id;
      fcmUnsubscribe["type"] = "UNSUBSCRIBE";
      await getTrackingDetails(fcmUnsubscribe);
    } catch (err) {
      setErrorMsg(err);
    }
  }

  React.useEffect(() => {    
    if ("Notification" in window)
    {
      const notificationStatus = Notification?.permission;
      if (notificationStatus !== "granted" && liveTracking === trackingType?.open) {
        setIsNotificationAlert(true);
      }
    }    
  }, [liveTracking]);

  React.useEffect(() => {  
    fcmData["fcmToken"] = !isNil(currentToken) ? currentToken : is_token;
    fcmData["trackingId"] = id;
    fcmData["session"] = liveTrackingId && liveTrackingId?.includes(id) ? false : true;
    getTrackingDetails(fcmData).then((res) => {
      if (res && res?.status) {
        setPopType("");
        const data = res?.result?.data?.coordinates?.sort(sortCoordinatesBasedOnTrackTime);
        const lastIndex = data?.length - 1;
        setCount(1);
        setTrackingData(res?.result?.data);        
        setFrequency(parseInt(res?.result?.data?.frequency));
        setLastUpdateSession(data[lastIndex]?.trackTime);
        setCenterPosition({ lat: data[0]?.lat, lng: data[0]?.lng });
        setStartPosition({ lat: data[0]?.lat, lng: data[0]?.lng, trackTime: data[0]?.trackTime });
        setIsUpdateTime({trackTime: data[lastIndex]?.trackTime});
        if (lastIndex > 0)
        {
          setEndPosition({ lat: data[lastIndex]?.lat, lng: data[lastIndex]?.lng, trackTime: data[lastIndex]?.trackTime });
        }
        if (data?.length > 2) {
          setPolyLines(data?.slice(1, data?.length - 1));
        }
        setLiveTracking(res?.result?.data?.trackStatus);
        setIsDataLoading(false);
        if (res?.result?.data?.trackStatus === trackingType?.close) {
          unsubscribeFcmNotifications();
        }
        const myTrackingIds = [];
        const previousId = liveTrackingId;
        previousId && previousId?.map((id) => {
          myTrackingIds.push(id);
        })
        if (previousId && !previousId?.includes(id)) {
          myTrackingIds.push(id);
        }
        setLiveTrackingId(myTrackingIds);
        setStorageValue(localStorageNames?.trackingId, myTrackingIds);
      } else {
        setIsValidId(false);
        setPopType(POP_TYPES?.ERROR);
        setIsModelOpen(true);
        setData(res?.result?.response?.data);
        setTrackingStatusCode(res?.result?.response?.data?.trackingStatusCode);
      }
    })
      .catch((err) => {
        setErrorMsg(err);
      })    
  }, [currentToken, refreshPage, id]);

  // To get the notification from fcm
  onMessageListener()
    .then((payload: any) => {
      if (payload) {
        const data = JSON.parse(payload?.data?.message);        
        if (data?.trackingId === id)
        {          
          const sortedCoordinates = data?.coordinates.sort(sortCoordinatesBasedOnTrackTime);
          const trackingStatus = data?.trackStatus === trackingType?.close ? data?.trackStatus : trackingType?.open;
          const lastIndex = sortedCoordinates?.length - 1;
          const center = Math.floor(lastIndex / 2);
          if (data && sortedCoordinates?.length >= 2) {
            setUpdatedFcmData(data);
            setCenterPosition(sortedCoordinates[center]);
            setStartPosition(sortedCoordinates[0]);
            if (lastIndex > 0)
            {
              setEndPosition(sortedCoordinates[lastIndex]);
            }
            if (sortedCoordinates?.length > 2) {
              setPolyLines(sortedCoordinates?.slice(1, sortedCoordinates?.length - 1));
            }
            setLiveTracking(trackingStatus);
            setLastUpdateSession(sortedCoordinates[lastIndex]?.trackTime);
            setUpdateLocation((current: boolean) => !current);
            if (trackingStatus === trackingType?.close) {
              unsubscribeFcmNotifications();
            }
          }
        }
      }
    })
    .catch((err) => {
      setErrorMsg(err);
    })    

  React.useEffect(() => {
    // To unsubscribe the fcm notification while tracking page changes
    return (() => {
      if (count > 0) {
        unsubscribeFcmNotifications();
      }
    })
  }, [count])

  React.useEffect(() => {
    let notificationStatus = "";
    if ("Notification" in window) {
      notificationStatus = Notification?.permission;
    }
    if (!isEmpty(isUpdateTime?.trackTime)) {
      const sessionDate = moment.utc(isUpdateTime?.trackTime).format("LTS");
      const serverDate = moment().utc().format("LTS");
      const timeDiff = parseInt(moment.utc(moment(serverDate, "HH:mm:ss").diff(moment(sessionDate, "HH:mm:ss"))).format("mm"));
      let timeInterval;
      if (timeDiff > 0 && ((frequency - (timeDiff * 60)) < 0)) {
        timeInterval = frequency * 1000;
      } else {
        timeInterval = (frequency - (timeDiff * 60)) * 1000;
      }
      const refreshMap = setInterval(() => {
        if (!isNil(timeInterval) &&
          notificationStatus !== "granted" && liveTracking === trackingType?.open ||
          getBrowserName(window.navigator.userAgent.toLowerCase()) === "Safari" ||
          isIosDevice() === "iOS") {
          setRefreshPage((current: boolean) => !current);
          setUpdateLocation((current: boolean) => !current);
        } else {
          clearInterval(refreshMap);
        }
      }, timeInterval);
      return () => {
        clearInterval(refreshMap)
      };
    }
  }, [liveTracking, endPosition]);

  React.useEffect(() => {
    if (!isEmpty(trackingData)) {
      setOpenWarning(true);
    }
  }, [trackingData, updateLocation]);

  const handleClose = async () => {
    try
    {
      await setIsModelOpen(false);
      await setIsNotificationAlert(false);
    }
    catch(err) {
      setErrorMsg(err);
    }
  }

  return (
    <>
      <ErrorBoundary>
        { isValidId ?
          ((debugMode === "on" && errorMsg) ? <div>{errorMsg}</div> 
            :
            (<>
              <Box 
                sx={{ 
                  width: "99%", 
                  margin: "5px", 
                  borderRadius: "0px"
                }}
              >
                <Collapse in={openWarning}>
                  <Alert
                    variant="outlined"
                    className="alert-style"
                    action={
                      <IconButton
                        color="warning"
                        size="small"
                        sx={{
                          color: colorCodes.startlingOrange
                        }}
                        onClick={() => {
                          setOpenWarning(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{
                      mb: 2,
                      fontFamily: "Roboto",
                      fontSize: "14px",
                      color: colorCodes.black,
                      border: "1px solid #E3612C",
                      "& .MuiAlert-icon": {
                        color: colorCodes.startlingOrange
                      }
                    }}
                    severity="info"
                  >
                    {(liveTracking === trackingType.close) ?              
                      (
                        <>
                          <StyledWebBanner>{t("session_banner_text")}&nbsp;
                            <a style={{color: colorCodes.burntSienna}}>{t("session_complete_text")} -&nbsp;</a>
                            <a style={{ fontSize: "13px", color: colorCodes.gray}}>
                              {t("session_ended_text")} {getUTCDateTime(lastUpdateSession)}
                            </a>
                          </StyledWebBanner> 
                          <StyledMobileBanner>{t("session_banner_text")}&nbsp;
                            <a style={{color: colorCodes.burntSienna}}>{t("session_complete_text")}</a><br/>
                            <a style={{ fontSize: "13px", color: colorCodes.gray}}>
                              {t("session_ended_text")} {getUTCDateTime(lastUpdateSession)}
                            </a>
                          </StyledMobileBanner>
                        </>
                      ) 
                      : 
                      (
                        <>
                          <StyledWebBanner>{t("session_banner_text")}&nbsp;
                            <a style={{color: colorCodes.burntSienna}}>{t("session_live_text")} -&nbsp;</a>
                            <a style={{ fontSize: "13px", color: colorCodes.gray}}>{t("session_update_text")} {getUTCDateTime(lastUpdateSession)}</a>
                          </StyledWebBanner>
                          <StyledMobileBanner>{t("session_banner_text")}&nbsp;
                            <a style={{color: colorCodes.burntSienna}}>{t("session_live_text")}</a><br/>
                            <a style={{ fontSize: "13px", color: colorCodes.gray}}>{t("session_update_text")} {getUTCDateTime(lastUpdateSession)}</a>
                          </StyledMobileBanner>
                        </>
                      )                                 
                    }                
                  </Alert>
                </Collapse>
              </Box>
              { !isDataLoading && startPosition?.lat !== 0 && startPosition?.lng !== 0 &&
          <Map
            centerPosition={centerPosition}
            startPosition={startPosition}
            endPosition={endPosition}
            polyLines={polyLines as any}
            isLiveTracking={liveTracking}
            isChildren={undefined}
          />
              }
              <liveTrackingContext.Provider value={window?.location?.href}>
                {trackingData && <Main updateLocation={updateLocation} trackingData={trackingData} updatedFcmData={updatedFcmData} />}
              </liveTrackingContext.Provider>
              <PopUp
                isModelOpen={popType && isModelOpen}
                type={popType}
                handleClose={handleClose}
                message={data}
              />
              <NotificationAlertPop
                isModelOpen={isNotificationAlert}
                closeModel={handleClose}
              />
            </>
            )) :
          <StatusMessage statusCode={trackingStatusCode}/>
        }
      </ErrorBoundary>
      <Footer/>
    </>
  );
}
