import React from "react";
import PlayStore from "../../../shared/icons/PlayStore.svg";
import AppleStore from "../../../shared/icons/AppleStore.svg";
import { Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colorCodes, fontFamily } from "../../../shared/styles/styles.config";

const StyledStackContainer = styled(Stack)`
  font-family: ${fontFamily.Roboto};
  color: ${colorCodes.darkCharcoal};
  width: 35vw;
  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledHead = styled(Stack)`
  background-color: ${colorCodes.whiteSmoke};
  height: 40px;
  border-radius: 10px 10px 0 0;
  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledTitleText = styled(Typography)`
  padding-bottom: 1rem;
  font-family: ${fontFamily.Roboto};
  font-size: 24px;
  font-weight: 700;
  color: ${colorCodes.darkCharcoal};
  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledText = styled(Typography)`
  padding-bottom: 1rem;
  font-family: ${fontFamily.Roboto};
  font-size: 16px;
  font-weight: 400px;
  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledIconsWrapper = styled(Stack)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  & div:first-of-type {
    margin-right: 1rem;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;

const StyledIconHolder = styled(Stack)`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid ${colorCodes.black};
  height: 71px;
  width: 86px;
  border-radius: 10px;
  border: 1px solid ${colorCodes.silver};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function DownloadSession() {
  const { t } = useTranslation();

  return (
    <StyledStackContainer>
      {/* For Dark top */}
      <StyledHead></StyledHead>
      <Stack
        sx={{
          padding: "1rem 2rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "space-around",
        }}
      >
        <StyledTitleText variant="h6">
          {t("download_header_text")}
        </StyledTitleText>
        <StyledText align="left">
          {t("download_section_text")}
        </StyledText>
        <StyledIconsWrapper>
          <StyledIconHolder>
            <a href="https://play.google.com/store/apps/details?id=com.bullitt.wave&hl=en-US&ah=QQ2q0y_nON5zWCwuFT8k4q9YQaY&pli=1" target="_blank" rel="noopener noreferrer">
              <img src={PlayStore} alt={t("play_store_logo_text")} />
            </a>
          </StyledIconHolder>
          <StyledIconHolder>
            <a href="https://apps.apple.com/us/app/bullitt-satellite-messenger/id6444013639?uo=2" target="_blank" rel="noopener noreferrer">
              <img src={AppleStore} alt={t("apple_store_logo_text")} />
            </a>
          </StyledIconHolder>
        </StyledIconsWrapper>
      </Stack>
    </StyledStackContainer>
  );
}
