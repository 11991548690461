import { getAxiosHeaders } from "./../../../shared/providers/requestMaker";
import { requestMaker } from "../../../shared/providers/requestMaker";
import { apiPath } from "../../../shared/utils/apiConfig";
import { serverTrackingURL } from "../../../shared/utils/Config";
import { REQUEST_TYPES } from "../../../shared/utils/Constants";

export const getContactList = async (userId: string) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.GET,
    url: `${serverTrackingURL}${apiPath?.GET_CONTACT_DETAILS}${userId}`,
    headers: getAxiosHeaders(false, true, false),
  });
  return response;
};

export const editContactList = async (data,loggedInUserMobile) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.PUT,
    url: `${serverTrackingURL}${apiPath?.EDIT_CONTACT_DETAILS}`,
    headers: getAxiosHeaders(false, true, false),
    data: {
      mobileNumber: loggedInUserMobile,
      trackingData: [
        {
          name: data?.name,
          value: data?.contactDetail
        }
      ]
    }
  });
  return response;
};

export const deleteContactList = async (data,loggedInUserMobile) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.PUT,
    url: `${serverTrackingURL}${apiPath?.DELETE_CONTACT_DETAILS}`,
    headers: getAxiosHeaders(false, true, false),
    data: {
      mobileNumber: loggedInUserMobile,
      trackingData: [
        {
          name: data?.name,
          value: data?.value
        }
      ]
    }
  });
  return response;
};

export const addContactList = async (data, loggedInUserMobile) => {
  const response = await requestMaker({
    method: REQUEST_TYPES?.PUT,
    url: `${serverTrackingURL}${apiPath?.ADD_CONTACT}`,
    headers: getAxiosHeaders(false, true, false),
    data: {
      mobileNumber: loggedInUserMobile,
      trackingData: [
        {
          name: data?.name,
          value: data?.contactDetail
        }
      ]
    }
  });
  return response;
};