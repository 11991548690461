import React from "react"
import { useTranslation } from "react-i18next";
import "./Contacts.css"
import EditIcon from "../../../shared/icons/edit.svg"
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ContactPopUp from "./AddContactPopUp";
import DeleteContact from "./DeleteContact";
import AddContact from "../../../shared/icons/AddContact.svg";
import { getContactList } from "../services/ContactServices";
import PopUp from "../../../shared/components/PopUp";
import { apiResponseCodes, invalidToken, localStorageNames, POP_TYPES } from "../../../shared/utils/Constants";
import { colorCodes, fontFamily } from "../../../shared/styles/styles.config";
import { styled } from "@mui/material";
import { getStorageValue, signOut } from "../../../shared/utils/common";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

const TableStyles = {  
  width: "100%",  
  color: "#979797",
  backgroundColor: colorCodes.white,  
  fontFamily: fontFamily.Roboto,
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "24px",
  letterSpacing: "0.2px",  
} as React.CSSProperties;

const StyledContectsWrapper = styled("div")`
  width: 60vw;
  margin: auto;
  @media (max-width: 1080px) {
      width: 90%;
  }
  @media (max-width: 900px) {
      width: 90%;
    }  
`;

const StyledTableContainer = styled("div")`
  ::-webkit-scrollbar {
    width: 6px;
    background-color: ${colorCodes.alto};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colorCodes.gray};
    border-radius: 10px;
  }
`;

function Contacts() {
  const { t } = useTranslation();
  const [openContactPopup, setOpenContactPopup] = React.useState<boolean | false>(false);
  const [editContact, setEditContact] = React.useState({});
  const [deleteContactPopUp, setDeleteContactPopUp] = React.useState<boolean | false>(false);
  const [deleteContact, setDeleteContact] = React.useState({});
  const [tableData, setTableData] = React.useState([]);
  const [newData, setNewData] = React.useState(false);
  const [popType, setPopType] = React.useState(POP_TYPES.LOADER);
  const [isModelOpen, setIsModelOpen] = React.useState(true);
  const [data, setData] = React.useState("");
  const [contactList, setContactList] = React.useState({});
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [loggedIn] = React.useState(getStorageValue(localStorageNames?.loggedIn));

  let contactNames = [];
  let contactDetails = [];

  React.useEffect(() => {
    if (loggedIn) {
      const loggedInUserMobile = JSON.parse(getStorageValue(localStorageNames?.loginDetails))?.mobileNo;
      getContactList(loggedInUserMobile).then((res) => {
        if (res && res?.result?.code === apiResponseCodes?.success) {
          setPopType("");
          setIsDataLoading(false);
          const data = res?.result?.data?.trackingContactList;
          setTableData(data);
          contactDetails = data?.map((obj) => {
            return obj?.value;
          });
          contactNames = data?.map((obj) => {
            return obj?.name;
          });
          setContactList({
            names: contactNames,
            values: contactDetails
          });
        }
        else if (res?.result?.response?.status === apiResponseCodes?.invalidRequest &&
                res?.result?.response?.data === invalidToken) {
          setPopType(POP_TYPES.ERROR);
          setData(t("authentication_error_msg"));
          setTimeout(() => {
            signOut();            
          }, 2000);
        }        
        else {
          setIsDataLoading(false)
          setPopType(POP_TYPES?.ERROR);
          setData(t("server_error_msg"));
        }
      })
    }
  }, [newData])

  const handleEditContact = (row) => {
    setOpenContactPopup(true);
    setEditContact(row);
  }

  const handleAddContact = () => {
    if (tableData?.length > 9)
    {
      setOpenWarning(true);
    }
    else
    {
      setOpenContactPopup(true);
    }
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    setOpenContactPopup(false);
    setDeleteContactPopUp(false);
    setEditContact({});
    setIsModelOpen(false);
  }

  return (
    <StyledContectsWrapper>
      <div className="contact-class">
        <div>
          <p>
            {t("default_contacts_header_text")}
          </p>
        </div>
        <div className="image-container">
          <img
            src={AddContact}
            alt={t("add_contact_text")}
            height="23px"
            width="44px"
            onClick={handleAddContact}
          />
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <Collapse in={openWarning}>
          <Alert
            variant="outlined"
            action={
              <IconButton                
                color="error"
                size="small"
                onClick={() => {
                  setOpenWarning(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ 
              mb: 2,
              fontFamily: "Roboto",
              fontSize: "14px",
              color: colorCodes.startlingOrange
            }}
            severity="error"
          >
            {t("contact_limit_warning_msg")}
          </Alert>
        </Collapse>        
      </Box>
      <StyledTableContainer className="contacts_table_container">
        <table className="contacts_table">          
          <React.Fragment>
            <thead>
              <tr>
                <th>{t("contact_Name")}</th>
                <th>{t("contact_details_text")}</th>
                <th>{t("actions_text")}</th>
              </tr>                
            </thead>              
            <tbody>
              { tableData?.length > 0 ?
                tableData?.map((row, i) => {
                  return (
                    <tr key={i}>
                      <td>{row?.name}</td>
                      <td>{row?.value}</td>
                      <td className="share_icons">
                        <img
                          className="hand_cursor"
                          src={EditIcon}
                          alt={t("edit_text")}
                          width="20px"
                          height="19px"
                          onClick={() => handleEditContact(row)} />
                        <PersonRemoveIcon
                          className="hand_cursor"
                          fontSize="small"
                          onClick={() => { setDeleteContactPopUp(true); setDeleteContact(row); } } />
                      </td>
                    </tr>
                  );
                })
                :
                !isDataLoading && 
                <tr>
                  <td></td>
                  <td><div style= {TableStyles}>{t("no_data_found")} </div></td>
                  <td></td>
                </tr>
              }
            </tbody>              
          </React.Fragment>          
        </table>
      </StyledTableContainer>

      <PopUp
        isModelOpen={popType && isModelOpen}
        type={popType}
        handleClose={handleClose}
        message={data}
      />

      {openContactPopup &&
        <ContactPopUp
          isModelOpen={true}
          isEditContact={editContact}
          contactData={contactList}
          setNewData={setNewData}
          handleClose={handleClose}
          setPopType= {setPopType}
          setIsModelOpen={setIsModelOpen}
          setData={setData}
        />
      }
      {
        deleteContactPopUp &&
        <DeleteContact
          isModelOpen={true}
          deleteContact={deleteContact}
          setNewData={setNewData}
          handleClose={handleClose}
          setPopType= {setPopType}
          setIsModelOpen={setIsModelOpen}
          setData={setData}
        />
      }
    </StyledContectsWrapper>
  )
}

export default Contacts