import * as React from "react";
import Pagination from "@mui/material/Pagination";
import "./Paginate.css";
import { colorCodes } from "../styles/styles.config";
import { trackingType } from "../utils/Constants";

export default function Paginate({ totalRecords, rowsPerPage, currentPage, trackingStatus }) {

  const [pageCount, setPageCount] = React.useState(Math.ceil(totalRecords / rowsPerPage));   
  const [defaultPageNo, setDefaultPage] = React.useState(1);

  const handleNextPage = (e, value) => { 
    setDefaultPage(value);
    currentPage(value);
  }

  const setPageNumber = () => {
    setPageCount(Math.ceil(totalRecords / rowsPerPage));  
    if ((trackingStatus?.length > 0) && (trackingStatus === trackingType.open))
    {
      setDefaultPage(Math.ceil(totalRecords / rowsPerPage));
    }
    else
    {
      setDefaultPage(1);
    }
  }

  React.useEffect(() => {    
    setPageNumber();    
  }, []);

  React.useEffect(() => {    
    setPageNumber();    
  }, [totalRecords, trackingStatus]);

  return (
    <div className="pages">      
      <Pagination 
        count={pageCount}  
        page={defaultPageNo}   
        siblingCount={0}
        sx={{ 
          ".MuiButtonBase-root.MuiPaginationItem-root.Mui-selected": {          
            backgroundColor: colorCodes?.startlingOrange,
            color: colorCodes?.white,              
          },
          ".MuiButtonBase-root.MuiPaginationItem-root:hover": {          
            backgroundColor: colorCodes?.startlingOrange,
            color: colorCodes?.white,              
          }
        }}
        onChange={handleNextPage}
      />
    </div>
  );
}