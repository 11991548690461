import { userSignOut } from "../../pages/authentication/services/authenticationService";
import { sessionType, trackingType } from "./Constants";
import moment from "moment";
import { BroadcastChannel } from "broadcast-channel";
import { pageNavPaths } from "./Config";

const logoutChannel = new BroadcastChannel("logout");

export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const duration = hours + " Hours " + minutes + " mins"
  return duration;
}

export const isPrivateSession = (trackingSessionType:String)=>{
  if(trackingSessionType){
    return trackingSessionType.toLocaleUpperCase() === sessionType.private.toLocaleUpperCase()
  }
}

export const isPublicSession = (trackingSessionType:String)=>{
  if(trackingSessionType){
    return trackingSessionType.toLocaleUpperCase() === sessionType.public.toLocaleUpperCase()
  }
}

export const getDateTime = (date) => {
  const dateTime = new Date(date);
  const momentDate = moment(dateTime);
  const formattedDate = momentDate.format("lll");     
  return formattedDate;
}

export const getUTCDateTime = (date) => {
  const dateTime = new Date(date);    
  const momentDate = moment(dateTime);       
  const momentUTCDateFormat = momentDate.format("D MMMM YYYY");    
  const momentUTCTime = momentDate.format("LT");     
  const extractTime = moment(momentUTCTime, "hh:mm:ss A").format("HH:mm:ss");  
  const formatTime = extractTime?.slice(0,5);
  const formattedDate = formatTime + ", " + momentUTCDateFormat;  
  return formattedDate;
}

export const getStorageValue = (key:string) => {
  return JSON.parse(localStorage.getItem(key));
};

export const setStorageValue = (key:string, value:any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeStorageValue = (key:string) => {
  localStorage.removeItem(key);
};

export const removeAllStorageValues = () => {
  localStorage.clear();
};

export const signOut = () => {
  userSignOut().then((res) => {
    if (res && res.status) {
      logoutChannel.postMessage("Logout");
      removeAllStorageValues();
      window.location.href = window.location.origin + "/";
    }
  })
}

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {    
    logoutChannel.close();
    if (window.location.pathname.startsWith(pageNavPaths?.liveTracking)) {
      window.location.reload();
    } else {
      window.location.href = window.location.origin + "/";
    }
  }
}

export const getBrowserName = (browser) => {
  switch (true) 
  {
  case (browser.indexOf("edg/") > -1): return "MS Edge";
  case (browser.indexOf("chrome") > -1 && browser.indexOf("edg/") < 1): return "Chrome";    
  case (browser.indexOf("firefox") > -1): return "Mozilla Firefox";
  case (browser.indexOf("safari") > -1): return "Safari";
  default: return "Other";
  }
}

export const isIosDevice = () => {
  if (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i)) {
    // iPad or iPhone
    return "iOS";
  } else {
    return "Other";
  }
}

// Sort coordinates based time stamps
export const sortCoordinatesBasedOnTrackTime = (a, b)=> {
  const timeA = new Date(a.trackTime);
  const timeB = new Date(b.trackTime);

  if (timeA < timeB) {
    return -1;
  }
  if (timeA > timeB) {
    return 1;
  }
  return 0;
}

export const isSessionExpired = (sessionData)=>{
  const { trackStatus, coordinates } = sessionData;

  if (trackStatus !== trackingType.close || sessionData?.trackingType !== sessionType.private) {
    return false;
  }

  // Check if there are coordinates and if the last coordinate is more than 7 days old
  if (coordinates && coordinates.length > 0) {
    const lastCoordinate = coordinates[coordinates.length - 1];
    const trackTime = new Date(lastCoordinate?.trackTime);
    const currentTime = new Date();

    const timeDifference = currentTime.getTime() - trackTime.getTime();

    // Convert 7 days to milliseconds (7 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const sevenDaysInMillis = 7 * 24 * 60 * 60 * 1000;

    // Check if the time difference is greater than 7 days
    if (timeDifference > sevenDaysInMillis) {
      return true;
    }
  }

  return false;
}

export const timeDiff = (dateStr1,dateStr2)=>{
  const date1 = new Date(dateStr1);
  const date2 = new Date(dateStr2);

  // Calculate the time difference in milliseconds
  const timeDifferenceMs = date2.getTime() - date1.getTime();

  // Convert milliseconds to minutes
  const minutesDifference = timeDifferenceMs / (1000 * 60);
  return minutesDifference
}

export const isCoordinatesAreZero = (lat, lng) => {
  if (lat.toString() === "0" && lng.toString() === "0") {
    return true;
  } else {
    return false;
  }
};