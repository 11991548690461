import React from "react";
import "./Preferences.css";
import { Button, MenuItem, Select, Typography, styled } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { colorCodes, fontFamily } from "../../../shared/styles/styles.config";
import { getPreferences, setPreferences } from "../services/PreferenceServices";
import { apiResponseCodes, invalidToken, localStorageNames, POP_TYPES, preferenceTypes, supportLink } from "../../../shared/utils/Constants";
import PopUp from "../../../shared/components/PopUp";
import { getStorageValue, signOut, setStorageValue } from "../../../shared/utils/common";
import Link from "@mui/material/Link";

interface IPreferencesProps {
  public?: string; 
  miles?: string;
  support?: () => void;
  checked?: boolean;
  onChange?: ()=> void;
  selectedLanguage?: any;
  userName?: string;
}

function Preferences(props:IPreferencesProps) {
  const [isMiles, setIsMiles] = useState<string | "">("");
  const [isPublic, setIsPublic] = useState<string | "">("");
  const { t } = useTranslation();
  const [popType, setPopType] = React.useState<string | "">(POP_TYPES.LOADER);
  const [isModelOpen, setIsModelOpen] = React.useState(true);
  const [msg, setMsg] = React.useState<string | "">("");
  const [loggedIn] = React.useState(getStorageValue(localStorageNames?.loggedIn));
  const loggedInUserMobile = JSON.parse(getStorageValue(localStorageNames?.loginDetails))?.mobileNo;
  const [shortName, setShortName] = React.useState("");
  const [selectedLanguage, setLanguage] = useState<string | "">("");

  React.useEffect(() => {
    if (loggedIn) {
      setStorageValue(localStorageNames?.currentPage, t("menu_item_preferences"))
      getPreferences(loggedInUserMobile).then((res) => {
        if (res && res?.result?.code === apiResponseCodes?.success) {
          setPopType("");
          if (res?.result?.data?.Miles) {
            setIsMiles("Miles");
          }
          else {
            setIsMiles("Kilometers");
          }    
          if (res?.result?.data?.Public) {
            setIsPublic("Public");
          }
          else {
            setIsPublic("Private");
          }
          setShortName(res?.result?.data?.userName);
          if(res?.result?.data?.Language){
            setLanguage(res?.result?.data?.Language)
          }else{
            setLanguage("en");
          }
        }
        else if (res.result.response.status === apiResponseCodes.invalidRequest &&
                res.result.response.data === invalidToken) {
          setPopType(POP_TYPES.ERROR);
          setMsg(t("authentication_error_msg"));
          setTimeout(() => {
            signOut();            
          }, 2000);
        }
        else {
          setPopType(POP_TYPES.ERROR);
          setMsg(t("server_error_msg"));
        }
      })
    }
  }, []);
  
  const languages = [          
    { value: "bs", text: "Bosnian" },
    { value: "bg", text: "Bulgarian" },
    { value: "frca", text: "Canadian French"},
    { value: "zh-rTW", text: "Chinese - Taiwan"},
    { value: "hr", text: "Croatian" },
    { value: "cs", text: "Czech" },    
    { value: "da", text: "Danish" },
    { value: "nl", text: "Dutch" },
    { value: "en", text: "English" },
    { value: "et", text: "Estonian" },
    { value: "fi", text: "Finnish" },      
    { value: "fr", text: "French" },
    { value: "de", text: "German" },
    { value: "el", text: "Greek" },
    { value: "hu", text: "Hungarian" },
    { value: "it", text: "Italian" },
    { value: "lv", text: "Latvian" },
    { value: "lt", text: "Lithuanian" },
    { value: "nb", text: "Norwegian" },
    { value: "pl", text: "Polish" },
    { value: "pt", text: "Portuguese" },
    { value: "ro", text: "Raeto-Romance" },
    { value: "ru", text: "Russian" },
    { value: "sr", text: "Serbian"},
    { value: "sk", text: "Slovak" },
    { value: "sl", text: "Slovenian"},
    { value: "es", text: "Spanish" },
    { value: "sv", text: "Swedish"},
  ];

  const units = [      
    { value: "Miles", text: "Miles" },  
    { value: "Kilometers", text: "Kilometers" }
  ];

  const profileType = [      
    { value: "Public", text: "Public" },  
    { value: "Private", text: "Private" }
  ];

  const handleLangChange = (event) => {    
    setLanguage(event.target.value);
    setStorageValue(localStorageNames.language, event.target.value);    
  };

  const handleUnitsChange = (event) => {   
    setIsMiles(event.target.value);
  };

  const handleProfileChange = (event) => {       
    setIsPublic(event.target.value);
  };

  const handleSave = () => {
    const data = [
      {
        configName: preferenceTypes.miles,
        configValue: isMiles === "Miles" ? true : false
      },
      {
        configName: preferenceTypes.public,
        configValue: isPublic === "Public" ? true : false
      },
      {
        configName: preferenceTypes.language,
        configValue: getStorageValue(localStorageNames?.language)
      },
      {
        configName: preferenceTypes?.userName,
        configValue: shortName
      }
    ];
    setPreferences(data, loggedInUserMobile).then((res) => {
      if (res && res?.result?.code === apiResponseCodes.success) {        
        setPopType(POP_TYPES.SUCCESS);
        setIsModelOpen(true);
        setMsg(t("preferences_success_Msg"));
        if (getStorageValue(localStorageNames?.language) !== localStorage.getItem("i18nextLng"))
        {
          setTimeout(() => {
            props?.selectedLanguage(getStorageValue(localStorageNames?.language));  
          }, 2000);                        
        }
      } 
      else if (res.result.response.status === apiResponseCodes.invalidRequest &&
              res.result.response.data === invalidToken) {
        setPopType(POP_TYPES.ERROR);
        setMsg(t("authentication_error_msg"));
        setTimeout(() => {
          signOut();          
        }, 2000);
      } 
      else {
        setPopType(POP_TYPES.ERROR);
        setIsModelOpen(true);
        setMsg(t("preferences_error_msg"));
      }
    })
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    setIsModelOpen(false);
  }

  const theme_2 = createTheme({
    palette: {
      primary: {
        main: colorCodes.tango,
      },
    },
  });

  const StyledSaveButton = styled(Button)`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    align-self: center;
    color: ${colorCodes.white};
    width: 212px;
    height: 55px;
    border-radius: 8px;
    padding: 1rem 0;    
  `;

  const StyledSupportLink = styled(Link)`
    background-color: ${colorCodes.white};
    font-size: 16px;
    font-weight: 300;
    font-family: 'Montserrat';
    color: ${colorCodes.black};
    margin: 2.5rem auto auto;
    text-decoration: underline;
    text-transform: none;    
    cursor: pointer;
  `;

  return (
    <>
      <div className="preference_container">
        <Typography variant="h5" sx={{ fontWeight: "300", fontSize: "26" }}>
          {t("preferences_header_text")}
        </Typography>
        <div className="toggle_section">          
          <div>
            <p>
              <span className="small-heading">{t("preferred_name_text")}</span><br />              
            </p>            
          </div>
          <div className="nick-name" onClick={() => {document.getElementById("shortname").focus()}}>
            <input    
              style={{ border: "none"}} 
              className="short-name"       
              type="text"
              id="shortname"
              placeholder={t("nick_name_text")}              
              value = {shortName}
              maxLength={10}
              onChange={(e) => {
                setShortName(e?.target?.value);
              }}
            />
          </div>
        </div>        
        <div className="toggle_section">          
          <div>
            <p>
              <span className="small-heading">{t("display_units_label_text")}</span><br />              
            </p>            
          </div>
          <div>
            <Select
              value={isMiles}
              onChange={handleUnitsChange}
              fullWidth
              sx={{height: 26, fontSize: "15px", fontFamily: fontFamily.Roboto, width: 135}}
            > 
              {units?.map((unit, i) => {
                return (<MenuItem sx={{height: 26, fontSize: "15px", fontFamily: fontFamily.Roboto}} key={i} value={unit?.value}>{unit?.text === "Kilometers" ? t("kilometers_text") : t("miles_text")}</MenuItem>)
              })}
            </Select>
          </div>
        </div>
        <div className="toggle_section">
          <div>
            <p>
              <span className="small-heading">{t("profile_text")}</span><br />              
            </p>
          </div>
          <div>
            <Select
              value={isPublic}
              onChange={handleProfileChange}
              fullWidth
              sx={{height: 26, fontSize: "15px", fontFamily: fontFamily.Roboto, width: 135}}
            > 
              {profileType?.map((profile, i) => {
                return (<MenuItem sx={{height: 26, fontSize: "15px", fontFamily: fontFamily.Roboto}} key={i} value={profile?.value}>{profile?.text === "Public" ? t("public_text") : t("private_text") }</MenuItem>)
              })}
            </Select>
          </div>
        </div>
        <p className="note">
          {t("preferences_notes_text")}
        </p>
        <div className="toggle_section">
          <div>
            <p>
              <span className="small-heading">{t("language_text")}</span>
            </p>            
          </div>
          <div>
            <Select
              value={selectedLanguage}
              onChange={handleLangChange}
              fullWidth
              sx={{height: 26, fontSize: "15px", fontFamily: fontFamily.Roboto, width:165}}
              MenuProps={{ PaperProps: { sx: { maxHeight: "30vh" } } }}
            > 
              {languages?.map((lan, i) => {
                return (<MenuItem sx={{height: 26, fontSize: "15px", fontFamily: fontFamily.Roboto}} key={i} value={lan?.value}>{lan?.text}</MenuItem>)
              })}

            </Select>
          </div>
        </div><br /><br />
        <ThemeProvider theme={theme_2}>
          <StyledSaveButton
            variant="contained"
            size="large"
            onClick={handleSave}
          >
            {t("save_button_text")}
          </StyledSaveButton>
        </ThemeProvider>
        <br /><br />
        <StyledSupportLink href={supportLink} target="_blank">
          {t("support_button_text")}
        </StyledSupportLink>
      </div>
      <PopUp
        isModelOpen={popType && isModelOpen}
        type={popType}
        handleClose={handleClose}
        message={msg}
        setData={setMsg}
      />
    </>
  );
}

export default Preferences;
