import axios from "axios";
import { getStorageValue } from "../utils/common";
import { deviceType, localStorageNames } from "../utils/Constants";
import { apikey } from "../utils/Config";

export const requestMaker = async (requestConfig) => {
  try {
    const response = await axios(requestConfig);
    return {
      status: true,
      result: response?.data
    };
  } catch (err) {
    return {
      status: false,
      result: err
    };
  }
};

export const getAxiosHeaders = (apiKey, userToken, addBearerToken) => {
  const headers = {};

  if (addBearerToken) {
    headers["Authorization"] = `Bearer ${JSON.parse(getStorageValue(localStorageNames?.loginDetails))?.access_token}`;
    headers["Content-Type"] = "application/json";
  }
  // only for login
  // if (apiKey) {
  //   headers["apikey"] = apikey;
  // }
  
  // send authorization token for all api calls
  if (userToken) {
    headers["x-api-key"] = process.env.REACT_APP_X_API_KEY;
  //   headers["user-token"] = JSON.parse(getStorageValue(localStorageNames?.loginDetails))?.refresh_token;
    // headers["deviceType"] = deviceType;
  }
  return headers;
};