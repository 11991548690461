import * as React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { t } from "i18next";

function DeletePop({closeModel}) {
  return (
    <div>
      <DialogTitle id="alert-dialog-title">
        {t("delete_session_text")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("delete_session_confirmation_text")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => closeModel()} autoFocus>{t("yes_button_text")}</Button>
        <Button onClick={()=>closeModel()}>{t("no_button_text")}</Button>
      </DialogActions>
    </div>
  );
}

export default DeletePop;