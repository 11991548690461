import React from "react";
import Share_icon from "../../shared/icons/share.svg";
import ReportErrorIcon from "../../shared/icons/resport_error.svg";
import { useTranslation } from "react-i18next";
import { supportLink } from "../utils/Constants";
import { colorCodes, fontFamily } from "../styles/styles.config";

function ErrorPop({ data, closeModel, signInError }) {
  const { t } = useTranslation();

  return (
    <div className="popup">
      <h3 className="popup_title">
        <img src={ReportErrorIcon} alt="error" />
        {signInError ? t("error_header"): t("error_code_text")}
      </h3>
      <p className="popup_message">{data}</p>

      <button className="dark_bg_btn">
        <a href={supportLink} target="_blank" rel="noopener noreferrer"
          style={{
            textDecoration: "none"            
          }}
        >
          <img 
            src={Share_icon} 
            alt={t("share_text")} 
            style={{ 
              paddingRight: "1.5em"                 
            }}
          />
          <a 
            style={{ 
              color: colorCodes.white,
              fontFamily: fontFamily.Roboto,
              fontSize: "12px",
              fontWeight: "600"              
            }}
          >
            {t("support_link_text")}
          </a>
        </a>
      </button>

      <button className="dismiss_btn" onClick={() => closeModel()}>
        {t("dismiss_button_text")}
      </button>
    </div>
  );
}

export default ErrorPop;
