const api = "api/";
const userService = "trackingservice/";
const trackingService = "trackingservice/";
const apiUserService = `${api}${userService}`
const apiTrackingService = `${api}${trackingService}`
const health = "health"

export const apiPath = {
  GET_CONTACT_DETAILS: `${apiUserService}tracking-contactList/`,
  EDIT_CONTACT_DETAILS: `${apiUserService}update-trackingContactList`,
  DELETE_CONTACT_DETAILS: `${apiUserService}del-trackingContactList`,
  ADD_CONTACT: `${apiUserService}add-trackingContactList`,
  TRACKING_DETAILS: `${apiTrackingService}fcm-tracking-token`,
  GET_PREFERENCES: `${apiUserService}webconfig/`,
  SET_PREFERENCES: `${apiUserService}add-update-webconfig`,
  TRACKING_TIME_LINE: `${apiTrackingService}tracking-timeLine/`,
  DELETE_SESSION: `${apiTrackingService}del-trackingSession`,
  GET_NOTIFICATIONS: `${apiTrackingService}get-readNotification/`,
  READ_NOTIFICATIONS: `${apiTrackingService}readNotification/`,
  USER_SIGN_IN: "token?grant_type=password",
  USER_SIGN_OUT: "logout",
  EDIT_SESSION: `${apiTrackingService}update-tracking-preference`,
  GET_SESSION_DATA: `${apiTrackingService}get-timeline-data-trackId/`,
  GET_LOGGED_IN_USER_INFO: `${health}/auth?jwt`,
  UPDATE_REFRESH_TOKENS_N_COOCKIES:"token?grant_type=refresh_token",
  VERIFY_TOKENS:`${health}/auth`
};
