import "./App.css";
import React from "react";
import Header from "./shared/components/Header";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Profile from "./pages/profile/components/Profile";
import Preferences from "./pages/preferences/components/Preferences";
import HistoryDetail from "./pages/profile/components/HistoryDetail";
import { useTranslation } from "react-i18next";
import LiveTracking from "./pages/liveTracking/components/LiveTracking";
import { pageNavPaths } from "./shared/utils/Config";
import Home from "./pages/home/components/Home";
import ErrorBoundary from "./shared/components/ErrorBoundary";
import Contacts from "./pages/contact/components/Contacts";
import { getStorageValue, removeAllStorageValues, setStorageValue } from "./shared/utils/common";
import { apiResponseCodes, localStorageNames, mapTypes } from "./shared/utils/Constants";
import StatusMessage from "./shared/components/statusMessage";
import SessionExpiredPop from "./shared/components/SessoinExpiredPop";
import { requestForToken } from "./shared/components/FirebaseInit";
import { logoutAllTabs } from "./shared/utils/common";
import Login from "./pages/authentication/components/Login";
import LocationFinder from "./pages/locationFinder/LocationFinder";
import { getLoggedInUserInfo, updateSessionAndCookies, userSignOut, verifyToken } from "./pages/authentication/services/authenticationService";
import { t } from "i18next";
import { getPreferences } from "./pages/preferences/services/PreferenceServices";
import { BroadcastChannel } from "broadcast-channel";

function App() {
  
  const [lang, setLanguage] = React.useState("");
  const { i18n } = useTranslation();
  const languageContext = React.createContext("");
  const [isSessionExpired, setIsSessionExpired] = React.useState(false);
  const [loggedIn] = React.useState(getStorageValue(localStorageNames?.loggedIn));
  const [openLogin, setOpenLogin] = React.useState(false);
  const [isUserDataFetching, setIsUserDataFetching] = React.useState(false);
  const logoutChannel = new BroadcastChannel("logout");



  const searchParams = new URLSearchParams(window.location?.search);
  const param = searchParams.get("return");
  
  const handleLanguageChange = (value) => {        
    i18n.changeLanguage(value);        
    setLanguage(value);  
  }

  const getUserPreferences = (loggedInUserMobile)=>{
    getPreferences(loggedInUserMobile).then((res) => {
      if (res && res?.result?.code === apiResponseCodes.success) {
        setIsUserDataFetching(false)
        if (res?.result?.data)
        {
          setStorageValue(localStorageNames?.isPreferencesFetched, true)
          setStorageValue(localStorageNames.language, res?.result?.data?.Language); 
          setStorageValue("i18nextLng", res?.result?.data?.Language);
          i18n.changeLanguage(res?.result?.data?.Language); 
          window.location.href = window.location.origin + pageNavPaths?.profile;
          // window?.location?.reload();
        }
        else
        {
          setStorageValue(localStorageNames.language, "en"); 
          setStorageValue("i18nextLng", "en"); 
          i18n.changeLanguage("en");       
          i18n.changeLanguage(res?.result?.data?.Language); 
          window.location.href = window.location.origin + pageNavPaths?.profile;          
          // window?.location?.reload();
        }                     
      }else{
        setIsUserDataFetching(false)
      }
    })
      .catch((err) => {
        setIsUserDataFetching(false)
        console.log(err);
      });
  }

  const getLoggedInUserDetails = ()=>{
    setIsUserDataFetching(true)
    getLoggedInUserInfo()
      .then((res:any)=>{
        if (res && res?.status == apiResponseCodes.success) {
          const tempUserName = res?.data?.jwt?.user_metadata?.name;
          const uName = tempUserName?.length > 0 ? tempUserName : res?.data?.jwt?.phone;
          const loginDetails = {
            access_token: res?.data?.at,
            mobileNo: res?.data?.jwt?.phone,
            userName: uName,
            refresh_token: res?.data?.rt,
          };
          setStorageValue(
            localStorageNames?.loginDetails,
            JSON.stringify(loginDetails)
          );
          setStorageValue(localStorageNames?.loggedIn, true);
          setStorageValue(localStorageNames?.currentPage, t("profile_text"));
          getStorageValue(localStorageNames?.isPreferencesFetched) !== true ? getUserPreferences(loginDetails?.mobileNo) : setIsUserDataFetching(false)
        }else{
          setIsUserDataFetching(false)
          setStorageValue(localStorageNames?.language,"en");
          setStorageValue(localStorageNames?.mapType, mapTypes?.terrain);
        }
      })
      .catch((err) => {
        setIsUserDataFetching(false)
        setStorageValue(localStorageNames?.language,"en");
        setStorageValue(localStorageNames?.mapType, mapTypes?.terrain);
        console.log(err);
      });
  }

  const updateUserSession = ()=>{
    updateSessionAndCookies()
      .then((res:any)=>{
        if (res && res?.status == apiResponseCodes.success) {
          const userLocalData = JSON.parse(getStorageValue(localStorageNames?.loginDetails))
          const loginDetails = {
            access_token: res?.data?.access_token,
            mobileNo: userLocalData?.mobileNo,
            userName: userLocalData?.userName,
            refresh_token: res?.data?.refresh_token,
          };
          setStorageValue(
            localStorageNames?.loginDetails,
            JSON.stringify(loginDetails)
          );
        }
      })
  }

  const verifyCurrentUser = () => {
    verifyToken().then((res:any) => {
      if (res && res?.status == apiResponseCodes?.success){
        updateUserSession()
      }
      else if(res?.result?.response?.status == apiResponseCodes?.invalidRequest){
        logout()
      }
    })
  };

  React.useEffect(()=>{
    if (!loggedIn)
    {
      getLoggedInUserDetails()
    }
    if(loggedIn){
      verifyCurrentUser()
    }
    if(!window.location.pathname.startsWith("/loc/")){
      requestForToken();
    }
  }, [])

  React.useEffect(() => {
    if (!loggedIn && param?.includes("profile")) {
      // setOpenLogin(true);
      window.location.href = `${process.env.REACT_APP_BULLITT_LOGIN_PAGE_URL}/login?redirect=${window.location.origin}`
    }
  }, [])

  React.useEffect(() => {     
    logoutAllTabs();    
  }, [])

  let timeoutHolder;
  
  const resetTimeout = () => {
    clearTimeout(timeoutHolder);
    timeoutHolder = setTimeout(logout, 30 * 60 * 1000); 
  };

  const logout = () => {
    userSignOut().then((res:any) => {
      if (res && res.status == apiResponseCodes?.noContentFound || res?.result?.response?.status == apiResponseCodes?.invalidRequest) {
        setIsSessionExpired((current: boolean) => !current);
        setTimeout(() => {
          logoutChannel.postMessage("Logout");
          removeAllStorageValues();
          if (window.location.pathname.startsWith(pageNavPaths?.liveTracking)) {
            window.location.reload();
          } else {
            window.location.href = window.location.origin + "/";
          }
        }, 3000);
      }
    })
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      return;
    setIsSessionExpired((current: boolean) => !current);
  }

  const handleSignInClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenLogin(false);
  }

  React.useEffect(() => {
    if (loggedIn)
    {
      timeoutHolder = setTimeout(logout, 30 * 60 * 1000);
      document.addEventListener("click", resetTimeout);
      document.addEventListener("keydown", resetTimeout);
    }
    
    return () => {
      document.removeEventListener("click", resetTimeout);
      document.removeEventListener("keydown", resetTimeout);
      clearTimeout(timeoutHolder);
    };
  }, []);
  
  return (
    <div className="App">  
      <BrowserRouter>
        <languageContext.Provider value={lang}>
          <div className="header_routes_container">
            <Header />             
            <ErrorBoundary>                          
              <Routes>
                {openLogin &&
                  <Route path={pageNavPaths?.home} element={ <>
                    <Home isUserDataFetching={isUserDataFetching} />
                    <Login handleClose={handleSignInClose} />
                  </>} />
                }
                <Route path={pageNavPaths?.home} element={ <Home isUserDataFetching={isUserDataFetching} /> } />
                <Route path={pageNavPaths?.liveTracking} element={ <LiveTracking /> } />                
                <Route path={pageNavPaths?.locatoin} element={ <LocationFinder /> } />                
                <Route path={pageNavPaths?.profile} element={ loggedIn ? <Profile /> : <Navigate to="/" /> } />
                <Route path={pageNavPaths?.profileDetails} element={loggedIn ? <HistoryDetail /> : <Navigate to="/" />} />
                <Route path={pageNavPaths?.preferences} element={loggedIn ? <Preferences selectedLanguage={handleLanguageChange} /> : <Navigate to="/" /> } />
                <Route path={pageNavPaths?.contacts} element={loggedIn ? <Contacts/> : <Navigate to="/" /> } />         
                <Route path="*" element={<StatusMessage statusCode={404} />} />
              </Routes>  
            </ErrorBoundary>
          </div>
        </languageContext.Provider>        
      </BrowserRouter>  
      <SessionExpiredPop
        isModelOpen={isSessionExpired}
        closeModel={handleClose}
      />
    </div>
  );
}

export default App; 