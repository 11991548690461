import React from "react";
import "./PopUp.css";
import { Dialog } from "@mui/material";
import LoaderPop from "./LoaderPop";
import ErrorPop from "./ErrorPop";
import DownloadPop from "./DownloadPop";
import { POP_TYPES } from "../utils/Constants";
import SharePop from "./SharePop";
import { useTranslation } from "react-i18next";
import SuccessPop from "./SuccessPop";
import EditSession from "./EditSession";
import DeletePop from "./DeletePop";

interface IPopUpProps {
  isModelOpen: boolean;
  type: string;
  downloadStatus?: Boolean;
  disableSwitch?: boolean;
  sessionData?: any;
  isZipDownload?:Boolean;
  signInError?: boolean;
  message?: string;
  handleClose: (event, reason) => any;
  setPopType?: (event, reason) => any;
  setIsModelOpen?: (event, reason) => any;
  setData?: (event, reason) => any;
  setNewData?: (event, reason) => any;
  sessionNames?: any;
  trackingId?: any;
}

export const sessionTracking = React.createContext("");

function PopUp(props: IPopUpProps) {

  const { t } = useTranslation();
  let data = {};
  
  if (props?.type !== POP_TYPES?.ERROR) {
    data = {
      title: props?.type === POP_TYPES?.LOADER ?
        t("loader_header_text") :
        props?.isZipDownload ?  t("export_session_as_zip_text") :t("export_session_text"),
      message: props?.type === POP_TYPES?.LOADER ? 
        t("request_processing_text") : 
        t("download_success_msg_text")
    };
  }
  
  return (
    props?.isModelOpen &&
    <Dialog
      open={props?.isModelOpen}
      onClose={props?.handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            borderRadius: "10px",
            maxWidth:
              (props?.type === POP_TYPES?.SHARE ||
                props.type === POP_TYPES?.DELETE)
                ?
                "fit-content" :
                "35rem",
          },
        },
      }}
    >
      {props?.type === POP_TYPES?.LOADER && <LoaderPop data={data} />}
      {props?.type === POP_TYPES?.ERROR && <ErrorPop data={props?.message} closeModel={props?.handleClose} signInError={props?.signInError} />}
      {props?.type === POP_TYPES?.DOWNLOAD && (
        <DownloadPop data={data} closeModel={props?.handleClose} />
      )}
      {props?.type === POP_TYPES?.SHARE && (
        <sessionTracking.Provider value={props.trackingId}>
          <SharePop closeModel={props?.handleClose} />
        </sessionTracking.Provider>
      )}
      {props?.type === POP_TYPES?.SUCCESS && (
        <SuccessPop closeModel={props?.handleClose} data={props?.message}/>
      )}
      {props?.type === POP_TYPES?.EDIT && 
        <EditSession 
          sessionData={props?.sessionData} 
          sessionNames={props?.sessionNames}
          disableSwitch={props?.disableSwitch} 
          closeModel={props?.handleClose}
          setPopType={props?.setPopType}
          setIsModelOpen={props?.setIsModelOpen}
          setData={props?.setData}
          setNewData={props?.setNewData}
        />
      }
      {props?.type === POP_TYPES?.DELETE && (
        <DeletePop closeModel={props?.handleClose} />
      )}
    </Dialog>
  )
}

export default PopUp;