import React, { useState } from "react";
import { Button, MenuItem, Select, styled, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import PopUp from "../../../shared/components/PopUp";
import { apiResponseCodes, app_type, localStorageNames, POP_TYPES } from "../../../shared/utils/Constants";
import { userSignIn } from "../services/authenticationService";
import { pageNavPaths } from "../../../shared/utils/Config";
import { useNavigate } from "react-router";
import { setStorageValue, getBrowserName } from "../../../shared/utils/common";
import { Dialog } from "@mui/material";
import { colorCodes, fontFamily } from "../../../shared/styles/styles.config";
import { getPreferences } from "../../preferences/services/PreferenceServices";

const StyledStack = styled(Stack)`
  flex-direction: column;
  align-items: start;
  padding: 1.5rem;
  background-color: ${colorCodes.white};
`;

const StyledSignInText = styled(Typography)`
  font-size: 1.3rem;
  line-height: 1.75rem;
  align-self: center;
  font-family: ${fontFamily.Roboto};
`;

const StyledPhoneDropHolder = styled(Stack)`
  width: 100%;  
  flex-direction: row;
  margin: 1rem 0;
  border: none;
`;

const StyledSelect = styled(Select)`
  width: 30%;  
  height: 10%;
  background-color: ${colorCodes.whiteSmoke};
  border-radius: 4px 0px 0px 4px !important;
`;

const StyledPhoneInput = styled(TextField)`
  width: 100%;
  & ::placeholder{
    font-size: 0.9rem;
  }
`;

const StyledPasswordInput = styled(TextField)`
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 4px;
  & ::placeholder{
    font-size: 0.9rem;
  }
`;

const StyledButton = styled(Button)`  
  width: 100%;
  background-color: ${colorCodes.tango};
  color: ${colorCodes.white};
  padding: 0.5rem 0;
  align-self: center;
  text-transform: none;
  margin-bottom: 0.5rem;
  &:hover{
    background-color: ${colorCodes.startlingOrange};
    color: ${colorCodes.white};
  }
`;

const StyledDismissBtn = styled("button")`
  margin-bottom: 0;
`

function Login({ handleClose }) {  
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("+91");
  const [phoneValidation, setPhoneValidation] = useState("");
  const [passwordValidation, setPasswordValidation] = useState("")  
  const [popType, setPopType] = useState("");
  const [isModelOpen] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [data, setData] = useState("");
  const [signInError, setSignInError] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  
  const countryCodes = [    
    {
      name:"Afghanistan",
      code:"+93"
    },
    {
      name:"Albania",
      code:"+355"
    },
    {
      name:"Algeria",
      code:"+213"
    },
    {
      name:"American Samoa",
      code:"+1"
    },
    {
      name:"Andorra",
      code:"+376"
    },
    {
      name:"Angola",
      code:"+244"
    },
    {
      name:"Anguilla",
      code:"+1"
    },
    {
      name:"Antigua and Barbuda",
      code:"+1"
    },
    {
      name:"Argentina",
      code:"+54"
    },
    {
      name:"Armenia",
      code:"+374"
    },
    {
      name:"Aruba",
      code:"+297"
    },
    {
      name:"Ascension Island",
      code:"+247"
    },
    {
      name:"Australia",
      code:"+61"
    },
    {
      name:"Austria",
      code:"+43"
    },
    {
      name:"Azerbaijan",
      code:"+994"
    },
    {
      name:"Bahamas",
      code:"+1"
    },
    {
      name:"Bahrain",
      code:"+973"
    },
    {
      name:"Bangladesh",
      code:"+880"
    },
    {
      name:"Barbados",
      code:"+1"
    },
    {
      name:"Belarus",
      code:"+375"
    },
    {
      name:"Belgium",
      code:"+32"
    },
    {
      name:"Belize",
      code:"+501"
    },
    {
      name:"Benin",
      code:"+229"
    },
    {
      name:"Bermuda",
      code:"+1"
    },
    {
      name:"Bhutan",
      code:"+975"
    },
    {
      name:"Bolivia",
      code:"+591"
    },
    {
      name:"Bosnia and Herzegovina",
      code:"+387"
    },
    {
      name:"Botswana",
      code:"+267"
    },
    {
      name:"Brazil",
      code:"+55"
    },
    {
      name:"British Indian Ocean Territory",
      code:"+246"
    },
    {
      name:"British Virgin Islands",
      code:"+1"
    },
    {
      name:"Brunei",
      code:"+673"
    },
    {
      name:"Bulgaria",
      code:"+359"
    },
    {
      name:"Burkina Faso",
      code:"+226"
    },
    {
      name:"Burundi",
      code:"+257"
    },
    {
      name:"Cambodia",
      code:"+855"
    },
    {
      name:"Cameroon",
      code:"+237"
    },
    {
      name:"Canada",
      code:"+1"
    },
    {
      name:"Cape Verde",
      code:"+238"
    },
    {
      name:"Caribbean Netherlands",
      code:"+599"
    },
    {
      name:"Cayman Islands",
      code:"+1"
    },
    {
      name:"Central African Republic",
      code:"+236"
    },
    {
      name:"Chad",
      code:"+235"
    },
    {
      name:"Chile",
      code:"+56"
    },
    {
      name:"China",
      code:"+86"
    },
    {
      name:"Christmas Island",
      code:"+61"
    },
    {
      name:"Cocos Islands",
      code:"+61"
    },
    {
      name:"Colombia",
      code:"+57"
    },
    {
      name:"Comoros",
      code:"+269"
    },
    {
      name:"Congo (DRC) ",
      code:"+243"
    },
    {
      name:"Congo (Republic)",
      code:"+242"
    },
    {
      name:"Cook Islands",
      code:"+682"
    },
    {
      name:"Costa Rica",
      code:"+506"
    },
    {
      name:"Côte d'Ivoire",
      code:"+225"
    },
    {
      name:"Croatia",
      code:"+385"
    },
    {
      name:"Cuba",
      code:"+53"
    },
    {
      name:"Curacao",
      code:"+599"
    },
    {
      name:"Cyprus",
      code:"+357"
    },
    {
      name:"Czech Republic",
      code:"+420"
    },
    {
      name:"Denmark",
      code:"+45"
    },
    {
      name:"Djibouti",
      code:"+253"
    },
    {
      name:"Dominica",
      code:"+1"
    },
    {
      name:"Dominican Republic",
      code:"+1"
    },
    {
      name:"Ecuador",
      code:"+593"
    },
    {
      name:"Egypt",
      code:"+20"
    },
    {
      name:"El Salvador",
      code:"+503"
    },
    {
      name:"Equatorial Guinea",
      code:"+240"
    },
    {
      name:"Eritrea",
      code:"+291"
    },
    {
      name:"Estonia",
      code:"+372"
    },
    {
      name:"Eswatini",
      code:"+268"
    },
    {
      name:"Ethiopia",
      code:"+251"
    },
    {
      name:"Falkland Islands",
      code:"+500"
    },
    {
      name:"Faroe Islands",
      code:"+298"
    },
    {
      name:"Fiji",
      code:"+679"
    },
    {
      name:"Finland",
      code:"+358"
    },
    {
      name:"France",
      code:"+33"
    },
    {
      name:"French Guiana",
      code:"+594"
    },
    {
      name:"French Polynesia",
      code:"+689"
    },
    {
      name:"Gabon",
      code:"+241"
    },
    {
      name:"Gambia",
      code:"+220"
    },
    {
      name:"Georgia",
      code:"+995"
    },
    {
      name:"Ghana",
      code:"+49"
    },
    {
      name:"Gibraltar",
      code:"+350"
    },
    {
      name:"Greece",
      code:"+30"
    },
    {
      name:"Greenland",
      code:"+299"
    },
    {
      name:"Grenada",
      code:"+1"
    },
    {
      name:"Guadeloupe",
      code:"+590"
    },
    {
      name:"Guam",
      code:"+1"
    },
    {
      name:"Guatemala",
      code:"+502"
    },
    {
      name:"Guernsey",
      code:"+44"
    },
    {
      name:"Guinea",
      code:"+224"
    },
    {
      name:"Guinea-Bissau",
      code:"+245"
    },
    {
      name:"Guyana",
      code:"+592"
    },
    {
      name:"Haiti",
      code:"+509"
    },
    {
      name:"Honduras",
      code:"+504"
    },
    {
      name:"Hong Kong",
      code:"+852"
    },
    {
      name:"Hungary",
      code:"+36"
    },
    {
      name:"Iceland",
      code:"+354"
    },
    {
      name:"India",
      code:"+91"
    },
    {
      name:"Indonesia",
      code:"+62"
    },
    {
      name:"Iran",
      code:"+98"
    },
    {
      name:"Iraq",
      code:"+964"
    },
    {
      name:"Ireland",
      code:"+353"
    },
    {
      name:"Isle of Man",
      code:"+44"
    },
    {
      name:"Israel",
      code:"+972"
    },
    {
      name:"Italy",
      code:"+39"
    },
    {
      name:"Jamaica",
      code:"+1"
    },
    {
      name:"Japan",
      code:"+81"
    },
    {
      name:"Jersey",
      code:"+44"
    },
    {
      name:"Jordan",
      code:"+962"
    },
    {
      name:"Kazakhstan",
      code:"+7"
    },
    {
      name:"Kenya",
      code:"+254"
    },
    {
      name:"Kiribati",
      code:"+686"
    },
    {
      name:"Kosovo",
      code:"+383"
    },
    {
      name:"Kuwait",
      code:"+965"
    },
    {
      name:"Kyrgyzstan",
      code:"+996"
    },
    {
      name:"Laos",
      code:"+856"
    },
    {
      name:"Latvia",
      code:"+371"
    },
    {
      name:"Lebanon",
      code:"+961"
    },
    {
      name:"Lesotho",
      code:"+266"
    },
    {
      name:"Liberia",
      code:"+231"
    },
    {
      name:"Libya",
      code:"+218"
    },
    {
      name:"Liechtenstein",
      code:"+423"
    },
    {
      name:"Lithuania",
      code:"+370"
    },
    {
      name:"Luxembourg",
      code:"+352"
    },
    {
      name:"Macau",
      code:"+853"
    },
    {
      name:"North Macedonia",
      code:"+389"
    },
    {
      name:"Madagascar",
      code:"+261"
    },
    {
      name:"Malawi",
      code:"+265"
    },
    {
      name:"Malaysia",
      code:"+60"
    },
    {
      name:"Maldives",
      code:"+960"
    },
    {
      name:"Mali",
      code:"+223"
    },
    {
      name:"Malta",
      code:"+356"
    },
    {
      name:"Marshall Islands",
      code:"+692"
    },
    {
      name:"Martinique",
      code:"+596"
    },
    {
      name:"Mauritania",
      code:"+222"
    },
    {
      name:"Mauritius",
      code:"+230"
    },
    {
      name:"Mayotte",
      code:"+262"
    },
    {
      name:"Mexico",
      code:"+52"
    },
    {
      name:"Micronesia",
      code:"+691"
    },
    {
      name:"Moldova",
      code:"+373"
    },
    {
      name:"Monaco",
      code:"+377"
    },
    {
      name:"Mongolia",
      code:"+976"
    },
    {
      name:"Montenegro",
      code:"+382"
    },
    {
      name:"Montserrat",
      code:"+1"
    },
    {
      name:"Morocco",
      code:"+212"
    },
    {
      name:"Mozambique",
      code:"+258"
    },
    {
      name:"Myanmar (Burma)",
      code:"+95"
    },
    {
      name:"Namibia",
      code:"+264"
    },
    {
      name:"Nauru",
      code:"+674"
    },
    {
      name:"Nepal",
      code:"+977"
    },
    {
      name:"Netherlands (Nederland)",
      code:"+31"
    },
    {
      name:"New Caledonia",
      code:"+687"
    },
    {
      name:"New Zealand",
      code:"+64"
    },
    {
      name:"Nicaragua",
      code:"+505"
    },
    {
      name:"Niger (Nijar)",
      code:"+227"
    },
    {
      name:"Nigeria",
      code:"+234"
    },
    {
      name:"Niue",
      code:"+683"
    },
    {
      name:"Norfolk Island",
      code:"+672"
    },
    {
      name:"North Korea",
      code:"+850"
    },
    {
      name:"Northern Mariana Islands",
      code:"+1"
    },
    {
      name:"Norway (Norge)",
      code:"+47"
    },
    {
      name:"Oman",
      code:"+968"
    },
    {
      name:"Pakistan",
      code:"+92"
    },
    {
      name:"Palau",
      code:"+680"
    },
    {
      name:"Palestine",
      code:"+970"
    },
    {
      name:"Panama",
      code:"+507"
    },
    {
      name:"Papua New Guinea",
      code:"+675"
    },
    {
      name:"Paraguay",
      code:"+595"
    },
    {
      name:"Peru",
      code:"+51"
    },
    {
      name:"Philippines",
      code:"+63"
    },
    {
      name:"Poland (Polska)",
      code:"+48"
    },
    {
      name:"Portugal",
      code:"+351"
    },
    {
      name:"Puerto Rico",
      code:"+1"
    },
    {
      name:"Qatar",
      code:"+974"
    },
    {
      name:"Réunion (La Réunion)",
      code:"+262"
    },
    {
      name:"Romania",
      code:"+40"
    },
    {
      name:"Russia",
      code:"+7"
    },
    {
      name:"Rwanda",
      code:"+250"
    },
    {
      name:"Saint Barthélemy",
      code:"+590"
    },
    {
      name:"Saint Helena",
      code:"+290"
    },
    {
      name:"Saint Kitts and Nevis",
      code:"+1"
    },
    {
      name:"Saint Lucia",
      code:"+1"
    },
    {
      name:"Saint Martin",
      code:"+590"
    },
    {
      name:"Saint Pierre and Miquelon",
      code:"+508"
    },
    {
      name:"Saint Vincent and the Grenadines",
      code:"+1"
    },
    {
      name:"Samoa",
      code:"+685"
    },
    {
      name:"San Marino",
      code:"+378"
    },
    {
      name:"Sao Tome and Principe",
      code:"+239"
    },
    {
      name:"Saudi Arabia",
      code:"+966"
    },
    {
      name:"Senegal",
      code:"+221"
    },
    {
      name:"Serbia",
      code:"+381"
    },
    {
      name:"Seychelles",
      code:"+248"
    },
    {
      name:"Sierra Leone",
      code:"+232"
    },
    {
      name:"Singapore",
      code:"+65"
    },
    {
      name:"Sint Maarten",
      code:"+1"
    },
    {
      name:"Slovakia (Slovensko)",
      code:"+421"
    },
    {
      name:"Slovenia (Slovenija)",
      code:"+386"
    },
    {
      name:"Solomon Islands",
      code:"+677"
    },
    {
      name:"Somalia (Soomaaliya)",
      code:"+252"
    },
    {
      name:"South Africa",
      code:"+27"
    },
    {
      name:"South Korea",
      code:"+82"
    },
    {
      name:"South Sudan",
      code:"+211"
    },
    {
      name:"Spain",
      code:"+34"
    },
    {
      name:"Sri Lanka",
      code:"+94"
    },
    {
      name:"Sudan",
      code:"+249"
    },
    {
      name:"Suriname",
      code:"+597"
    },
    {
      name:"Svalbard and Jan Mayen",
      code:"+47"
    },
    {
      name:"Sweden (Sverige)",
      code:"+46"
    },
    {
      name:"Switzerland (Schweiz)",
      code:"+41"
    },
    {
      name:"Syria",
      code:"+963"
    },
    {
      name:"Taiwan",
      code:"+886"
    },
    {
      name:"Tajikistan",
      code:"+992"
    },
    {
      name:"Tanzania",
      code:"+255"
    },
    {
      name:"Thailand",
      code:"+66"
    },
    {
      name:"Timor-Leste",
      code:"+670"
    },
    {
      name:"Togo",
      code:"+228"
    },
    {
      name:"Tokelau",
      code:"+690"
    },
    {
      name:"Tonga",
      code:"+676"
    },
    {
      name:"Trinidad and Tobago",
      code:"+1"
    },
    {
      name:"Tunisia",
      code:"+216"
    },
    {
      name:"Turkey",
      code:"+90"
    },
    {
      name:"Turkmenistan",
      code:"+993"
    },
    {
      name:"Turks and Caicos Islands",
      code:"+1"
    },
    {
      name:"Tuvalu",
      code:"+688"
    },
    {
      name:"U.S. Virgin Islands",
      code:"+1"
    },
    {
      name:"Uganda",
      code:"+256"
    },
    {
      name:"Ukraine",
      code:"+380"
    },
    {
      name:"United Arab Emirates",
      code:"+971"
    },
    {
      name:"United Kingdom",
      code:"+44"
    },
    {
      name:"United States",
      code:"+1"
    },
    {
      name:"Uruguay",
      code:"+598"
    },
    {
      name:"Uzbekistan",
      code:"+998"
    },
    {
      name:"Vanuatu",
      code:"+678"
    },
    {
      name:"Vatican City",
      code:"+39"
    },
    {
      name:"Venezuela",
      code:"+58"
    },
    {
      name:"Vietnam",
      code:"+84"
    },
    {
      name:"Wallis and Futuna (Wallis-et-Futuna)",
      code:"+681"
    },
    {
      name:"Western Sahara",
      code:"+212"
    },
    {
      name:"Yemen",
      code:"+967"
    },
    {
      name:"Zambia",
      code:"+260"
    },
    {
      name:"Zimbabwe",
      code:"+263"
    },
    {
      name:"Aland Islands",
      code:"+358"
    },
  ];
    
  const handleClosePopup = () => {  
    setShowMessage(false);  
  }

  const getUsetPreferences = (loggedInUserMobile)=>{
    getPreferences(loggedInUserMobile).then((res) => {
      if (res && res?.result?.code === apiResponseCodes.success) {
        if (res?.result?.data)
        {
          setStorageValue(localStorageNames.language, res?.result?.data?.Language); 
          setStorageValue("i18nextLng", res?.result?.data?.Language);
          handleClose(); //for closing login popup
          setPopType(""); // for making opened popup type empty
          setShowMessage(false); // for closing popup (loader)
          navigate(pageNavPaths?.profile);
          i18n.changeLanguage(res?.result?.data?.Language); 
          window?.location?.reload();
        }
        else
        {
          handleClose();
          setStorageValue(localStorageNames.language, "en"); 
          setStorageValue("i18nextLng", "en"); 
          i18n.changeLanguage("en");       
          setPopType("");
          setShowMessage(false);
          navigate(pageNavPaths?.profile);
          i18n.changeLanguage(res?.result?.data?.Language); 
          window?.location?.reload();
        }                     
      }
    })
  }

  const handleSignIn = () => {
    if (phoneNumber?.length === 0)
    {
      setPhoneValidation(t("phone_null_msg"));
    }
    if (password?.length === 0)
    {
      setPasswordValidation(t("password_null_msg"));
    }
    else 
    {
      setPhoneValidation("");
      setPasswordValidation("");
      setPopType(POP_TYPES.LOADER)
      setShowMessage(true)    
      const data = {
        phone: `${selectedCountry}${phoneNumber}`,
        password: password,
        deviceType: getBrowserName(window.navigator.userAgent.toLowerCase()),
        appType: app_type
      }
      userSignIn(data).then((res) => {
        if (res && res.status) {
          const tempUserName = res?.result?.user?.user_metadata?.name;
          const uName = tempUserName?.length > 0 ? tempUserName : res?.result?.user?.phone
          const loginDetails = {
            access_token: res?.result?.access_token,
            mobileNo: res?.result?.user?.phone,
            userName: uName,
            refresh_token:res?.result?.refresh_token
          };
          setStorageValue(localStorageNames?.loginDetails, JSON.stringify(loginDetails));        
          setStorageValue(localStorageNames?.loggedIn, true);
          setStorageValue(localStorageNames?.currentPage, t("profile_text"));
          getUsetPreferences(loginDetails?.mobileNo)              
        } else {
          const errorData = res?.result?.response?.data;
          const invalidError = errorData?.error_description === "Invalid login credentials" ? t("sign_error") : errorData?.error_description;
          setShowMessage(true);
          setSignInError(true);
          setPopType(POP_TYPES?.ERROR);
          setData(invalidError);
        }
      })
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSignIn()
    }
  };

  return (    
    <>
      <Dialog
        open={isModelOpen}
        onClose={handleClose}
        onKeyDown={handleKeyDown}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              minWidth: "22.5rem",
              borderRadius: "10px",
            },
            "@media (max-width:360px)": {
              "& .MuiPaper-root":{
                minWidth:"99%"
              }
            },
          },
        }}
      >
        <StyledStack>
          <StyledSignInText >{t("login_page_sign_in_text")}</StyledSignInText>
          <StyledPhoneDropHolder>
            <StyledSelect 
              size="small"
              MenuProps={{ PaperProps: { sx: { maxHeight: "30vh" } } }}            
              defaultValue={selectedCountry} 
              value={selectedCountry}            
              onChange={(e)=>setSelectedCountry(e.target.value as string)}>
              {
                countryCodes?.map(country=>{
                  return <MenuItem key={country?.code + country?.name} value={country?.code}>{country?.code}</MenuItem>
                })
              }
            </StyledSelect>
            <StyledPhoneInput
              size="small" 
              placeholder={t("mobile_number")}
              value={phoneNumber}     
              error={phoneValidation?.length > 0 ? true : false}
              helperText={phoneValidation}        
              type="phone"
              sx={{
                ".MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-sizeSmall": {
                  borderRadius: "none",                  
                  borderTopLeftRadius: "0px !important",
                  borderBottomLeftRadius: "0px !important",
                  borderTopRightRadius: "4px !important",
                  borderBottomRightRadius: "4px !important"
                }
              }}
              onChange={(e)=> {
                setPhoneNumber(e?.target?.value);              
              }}
            />
          </StyledPhoneDropHolder>                
          <StyledPasswordInput 
            size="small" 
            placeholder={t("password")} 
            value={password}
            error={passwordValidation?.length > 0 ? true : false}
            helperText={passwordValidation}
            type="password"
            onChange={(e) => {
              setPassword(e?.target?.value);            
            }}
          />        
          <StyledButton onClick={handleSignIn}>{t("login_page_sign_in_text")}</StyledButton>
          <StyledDismissBtn className="dismiss_btn" onClick={handleClose}>
            {t("dismiss_button_text")}
          </StyledDismissBtn>
        </StyledStack>
      </Dialog>          
      <PopUp
        isModelOpen={popType && showMessage}
        type={popType}
        handleClose={handleClosePopup}
        message={data}
        signInError={signInError}
      /> 
    </>
  );
}

export default Login;