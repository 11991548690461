import React from "react";
import { useTranslation } from "react-i18next";

function SuccessPop({ closeModel, data }) {
  const { t } = useTranslation();

  return (
    <div className="popup">
      <h3 className="popup_title">
        {t("success_text")}
      </h3>
      <p className="popup_message">{data}</p>

      <button className="dismiss_btn" onClick={() => closeModel()}>
        {t("dismiss_button_text")}
      </button>
    </div>
  );
}

export default SuccessPop;
