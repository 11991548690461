import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import { styled } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { colorCodes, fontFamily, fontWeight } from "../styles/styles.config";
import ListItemIcon from "@mui/material/ListItemIcon";
import { getUTCDateTime } from "../utils/common";
import { useNavigate } from "react-router-dom";
import { pageNavPaths } from "../utils/Config";
import Tracking_Start from "../icons/Tracking_Start.svg";
import Tracking_End from "../icons/Tracking_End.svg";
import "./MobileNotifications.css";

const StyledBox = styled(Box)` 
  display: block;

  @media (min-width: 900px) {
      display: none;        
  }
`;

const StyledList = styled(List)` 
  width: 100vw;
`;

const StyledNotificationList = styled(MenuList)`
  width: 90vw;
  padding-left: 10px;
  overflow-y: auto;
  overflow-x: hidden; 
  
  ::-webkit-scrollbar {
    width: 6px;
    background-color: ${colorCodes.alto};
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: ${colorCodes.gray};
    border-radius: 10px;
  }  
`;

const StyledListIcon = styled(ListItemIcon)`
  width: 12%;
`;

const StyledNotificationText = styled(Typography)`
  width: 80%;  
  font-family: ${fontFamily.Roboto};
  font-style: normal;    
  font-size: 12px;
  line-height: 16px;
  color: ${colorCodes.black};
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  order: 2;
  white-space: normal;
  flex-direction: row;  
`;

const StyledNotificationTime = styled(Typography)`  
  flex-basis: 7rem;  
  font-family: ${fontFamily.Roboto};
  font-style: normal;
  font-size: 10px;
  line-height: 12px;
  color: ${colorCodes.black};
  order: 3;
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  white-space: normal;
  flex-direction: row;
  align-items: center;
`;

export default function MobileMenu({ notifications, viewed, isOpen, readNotification }) {        
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = React.useState(true);    
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
    isOpen(open);
  };

  const handleNotificationClick = (nontification)=>{
    navigate(`${pageNavPaths?.liveTracking}?id=${nontification?.trackingId}`);
    readNotification();
  }
  
  const list = () => (
    <StyledBox    
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}      
    >
      <StyledList>
        <ListItem key="" disablePadding>                
          <ListItemButton> 
            <ChevronLeftIcon onClick={()=> readNotification()} /> &nbsp;&nbsp;&nbsp;
            {t("notifications_label")}
          </ListItemButton>
        </ListItem>
        <Divider/>
      </StyledList>         
      { notifications &&            
        <StyledNotificationList >
          { 
            notifications?.map((notification, index)=>{  
              return (                                                                                             
                <MenuItem key={index} onClick={()=>handleNotificationClick(notification)}>                  
                  <StyledListIcon>                   
                    {notification?.statusCode === 200 ? <img src={Tracking_Start} className="Tracking-icon" alt={t("tracking_start")} /> : <img src={Tracking_End} className="Tracking-icon" alt={t("tracking_end")} />}
                  </StyledListIcon>                    
                  <StyledNotificationText
                    sx={{
                      fontWeight: notification.read || viewed
                        ? fontWeight?.read 
                        : 
                        fontWeight?.unread
                    }}
                  >                    
                    {notification?.statusCode === 200 ? `${t("tracking_started")} - ${notification?.sessionName}` : `${t("tracking_ended")} - ${notification?.sessionName}`}
                  </StyledNotificationText>
                  <StyledNotificationTime
                    sx={{
                      fontWeight: notification.read || viewed
                        ? fontWeight?.read 
                        :
                        fontWeight?.unread
                    }}
                  >
                    {getUTCDateTime(notification?.time)}
                  </StyledNotificationTime> 
                </MenuItem>
              )})}
        </StyledNotificationList>
      }
    </StyledBox>
  );

  return (
    <div>      
      <React.Fragment key={"right"}>          
        <SwipeableDrawer
          anchor={"right"}
          open={openDrawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableBackdropTransition={true}                     
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>      
    </div>
  );
}