import React from "react";
import "./Home.css";
import { t } from "i18next";
import HomePage from "../../../shared/images/home_background.svg";
import BullIcon from "../../../shared/images/Bull_Icon_White.svg";
import Fab from "@mui/material/Fab";
import { colorCodes, fontFamily } from "../../../shared/styles/styles.config";
import { styled } from "@mui/material";
import { getStorageValue } from "../../../shared/utils/common";
import { POP_TYPES, localStorageNames } from "../../../shared/utils/Constants";
import { isNil } from "lodash";
import { pageNavPaths } from "../../../shared/utils/Config";
import Login from "../../authentication/components/Login";
import PopUp from "../../../shared/components/PopUp";

const StyledFabButton = styled(Fab)`        
    width: 110px;
    height: 40px;    
    position: absolute;
    bottom: 20%;    
    left: 50%;
    font-family: ${fontFamily.Roboto};
    font-style: normal;
    font-weight: 600;
    font-size: 16px;    
    text-transform: none;
    transform: translate(-50%, -50%);    
    border: none;
    border-radius: 40px;
    background: ${colorCodes.white};
    box-shadow: none;          
    white-space: normal;

    &.MuiButtonBase-root-MuiFab-root {        
        disableRipple: true; 
      }
    @media (max-width: 1024px) {      
      bottom: 28%;    
    }
    @media (max-width: 900px) {      
      bottom: 23%;    
    }
`;

function Home({ isUserDataFetching }) {
  const [openLogin, setOpenLogin] = React.useState(false);

  const handleSignIn = () => {
    if (!isNil(getStorageValue(localStorageNames?.loggedIn)))
    {
      window.location.href = window.location.origin + pageNavPaths?.profile;
    }
    if (isNil(getStorageValue(localStorageNames?.loggedIn)))
    {
      window.location.href = `${process.env.REACT_APP_BULLITT_LOGIN_PAGE_URL}/login?redirect=${window.location.origin}`
      // setOpenLogin(true);
    }
  }

  const handleSignInClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setOpenLogin(false);
  }

  return (
    <>
      <div className="home-container">
        <img
          src={HomePage}
          alt={t("home_image")}
          className="home_img"
        />
        <div className="text_image_container">        
          <div className="location-text">{t("home_page_image_text")}</div> 
          <img
            src={BullIcon}
            alt={t("home_image")}
            className="bull_img"
          />            
        </div>        
        <div className="footer-text">{t("home_page_footer_text")}</div>
        {getStorageValue(localStorageNames?.loggedIn) !== true &&
          <StyledFabButton
            variant="extended"
            disableTouchRipple={true}
            disableRipple={true}
            onClick={handleSignIn}
          >
            {t("login_button_text")}
          </StyledFabButton>
        }     
      </div>   
      {openLogin &&
        <Login handleClose={handleSignInClose} />
      }   
      <PopUp isModelOpen={isUserDataFetching} type={POP_TYPES?.LOADER}/>
      <PopUp
        isModelOpen={isUserDataFetching}
        type={POP_TYPES?.LOADER}
      />
    </> 
  );
}

export default Home;