import JSZip from "jszip";

export const gpxFileDownloader = (coordinatesArrays, sessionNamesArray, setDownloadStatus) => {
  if (coordinatesArrays?.length === 1) 
  {
    const coordinates = coordinatesArrays[0];
    const sessionName = sessionNamesArray[0];
    const gpx = `<?xml version="1.0"?>
      <gpx
        version="1.1"
        creator="BMS Tracking Portal"
        xmlns="http://www.topografix.com/GPX/1/1"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
        xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">

        <trk>
          <name>${sessionName}</name>
          <trkseg>
            ${coordinates?.map(c => `
              <trkpt lat="${c?.lat}" lon="${c?.lng}"></trkpt>
            `).join("")}
          </trkseg>
        </trk>
      </gpx>`;

    setTimeout(
      ()=>{const blob = new Blob([gpx], { type: "application/gpx+xml" });
        const link = document.createElement("a");
        link.download = `${sessionName}.gpx`;
        link.href = window.URL.createObjectURL(blob);
        link.click();
        setDownloadStatus(true)}
      , 100)
  }
  else
  {
    const zip = new JSZip();
    coordinatesArrays?.forEach((coordinates, index) => {
      const gpx = `<?xml version="1.0"?>
        <gpx
          version="1.1"
          creator="BMS Tracking Portal"
          xmlns="http://www.topografix.com/GPX/1/1"
          xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
          xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd">

          <trk>
            <name>${sessionNamesArray[index]}</name>
            <trkseg>
              ${coordinates?.map(c => `
                <trkpt lat="${c?.lat}" lon="${c?.lng}"></trkpt>
              `).join("")}
            </trkseg>
          </trk>
        </gpx>`;
      
      zip.file(`${sessionNamesArray[index]}.gpx`, gpx);
    });
  
    setTimeout(
      ()=>{zip.generateAsync({ type: "blob" }).then(content => {
        const link = document.createElement("a");
        link.download = "BSM-Tracking.zip";
        link.href = window.URL.createObjectURL(content);
        link.click();
      });
      setDownloadStatus(true)}
      , 100);
  }
};